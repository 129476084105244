import { ReactNode, createContext, useContext } from 'react';
import { FormInputAnswerService } from './FormInputAnswerService';

const ServiceContext = createContext<FormInputAnswerService>(
  new FormInputAnswerService()
);

export function FormInputAnswerServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const formInputAnswerService = new FormInputAnswerService();

  return (
    <ServiceContext.Provider value={formInputAnswerService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useFormInputAnswerServiceService(): FormInputAnswerService {
  return useContext(ServiceContext);
}
