import axios from 'axios';
import { ModelVersionsResponse, VersionParams } from '../types';

export class VersionService {
  async getVersions(params: VersionParams): Promise<ModelVersionsResponse> {
    const containerId =
      params.containerId != null ? `&container_id=${params.containerId}` : '';
    const referenceModelType =
      params.referenceModelType != null
        ? `&reference_model_type=${params.referenceModelType}`
        : '';
    const referenceModelId =
      params.referenceModelId != null
        ? `&reference_model_id=${params.referenceModelId}`
        : '';

    const response = await axios.get(
      `/versions?model_type=${params.modelType}&model_id=${params.modelId}${containerId}${referenceModelType}${referenceModelId}`
    );

    return response.data;
  }
}
