import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const AwardDefinitions = lazy(
  () => import('../pages/table-page/AwardDefinitionTablePage')
);
const AwardDefinition = lazy(
  () => import('../pages/flow-page/AwardDefinitionFlowPage')
);

const AwardDefinitionRoutes = () => (
  <Routes>
    <Route path="" element={<AwardDefinitions />} />
    <Route path="/:awardDefinitionId" element={<AwardDefinition />} />
  </Routes>
);

export default AwardDefinitionRoutes;
