import SidebarContent from './SidebarContent';
import { useAwardService } from '@/features/awards/services/useAwardService';
import AsyncComponent from '@/components/AsyncComponent';

const SidebarMenu = () => {
  const awardService = useAwardService();

  return (
    <AsyncComponent
      queryKey="award programs"
      fetch={() => awardService.getAwardPrograms()}
      loaderType="sidebar"
      builder={(data) => <SidebarContent grants={data.awardPrograms} />}
    />
  );
};

export default SidebarMenu;
