import CableProvider from './CableProvider';
import { CpgTemplateServiceProvider } from '@/features/cpg-templates/services/useCpgTemplateServices';
import { CpgServiceProvider } from '@/features/cpg-documents/services/useCpgServices';
import { AwardServiceProvider } from '@/features/awards/services/useAwardService';
import { IamServiceProvider } from '@/features/iam/services/useIamService';
import { WorkPlanTemplateServicesProvider } from '@/features/work-plan-templates/services/useWorkPlanTemplateServices';
import { ReactNode } from 'react';
import { WorkPlanDocumentServiceProvider } from '@/features/work-plan-documents/services/useWorkPlanDocumentServices';
import { SpendPlanTemplateServicesProvider } from '@/features/spend-plan-templates/services/useSpendPlanTemplateServices';
import { SpendPlanDocumentServiceProvider } from '@/features/spend-plan-documents/services/useSpendPlanDocumentServices';
import { ReportServiceProvider } from '@/features/reports/services/useReportService';
import { InvoiceServiceProvider } from '@/features/invoices/services/useInvoiceService';
import { CommentServiceProvider } from '@/features/comments/services/useCommentService';
import { NotificationsServiceProvider } from '@/features/notifications/services/useNotificationService';
import { FileStorageServiceProvider } from '@/features/file-storage/services/useFileStorageService';
import { ConfigServiceProvider } from '@/features/config/services/useConfigService';
import { FileDownloadServiceProvider } from '@/features/file-download/services/useFileDownloadService';
import { HelpCenterServiceProvider } from '@/features/help-center/services/useHelpCenterService';
import { ExpenditureReportServiceProvider } from '@/features/expenditure-report/services/useExpenditureReportService';
import { PersonnelReportServiceProvider } from '@/features/personnel-report/services/usePersonnelReportService';
import { AnnouncementServiceProvider } from '@/features/announcements/services/useAnnouncementService';
import { VersionServiceProvider } from '@/features/versions/services/useVersionService';
import { FormInputAnswerServiceProvider } from '@/features/form-inputs/services/useFormInputAnswerService';
import { AccountSettingsServiceProvider } from '@/features/account-settings/services/AccountSettingsService';
import { AwardDefinitionServicesProvider } from '@/features/award-definitions/services/useAwardDefinitionServices';

export default function FeatureServicesProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <IamServiceProvider>
      <CableProvider>
        <CpgTemplateServiceProvider>
          <CpgServiceProvider>
            <WorkPlanTemplateServicesProvider>
              <WorkPlanDocumentServiceProvider>
                <SpendPlanTemplateServicesProvider>
                  <SpendPlanDocumentServiceProvider>
                    <InvoiceServiceProvider>
                      <AwardServiceProvider>
                        <CommentServiceProvider>
                          <ReportServiceProvider>
                            <FileStorageServiceProvider>
                              <FileDownloadServiceProvider>
                                <NotificationsServiceProvider>
                                  <HelpCenterServiceProvider>
                                    <ConfigServiceProvider>
                                      <ExpenditureReportServiceProvider>
                                        <PersonnelReportServiceProvider>
                                          <AnnouncementServiceProvider>
                                            <VersionServiceProvider>
                                              <AccountSettingsServiceProvider>
                                                <FormInputAnswerServiceProvider>
                                                  <AwardDefinitionServicesProvider>
                                                    {children}
                                                  </AwardDefinitionServicesProvider>
                                                </FormInputAnswerServiceProvider>
                                              </AccountSettingsServiceProvider>
                                            </VersionServiceProvider>
                                          </AnnouncementServiceProvider>
                                        </PersonnelReportServiceProvider>
                                      </ExpenditureReportServiceProvider>
                                    </ConfigServiceProvider>
                                  </HelpCenterServiceProvider>
                                </NotificationsServiceProvider>
                              </FileDownloadServiceProvider>
                            </FileStorageServiceProvider>
                          </ReportServiceProvider>
                        </CommentServiceProvider>
                      </AwardServiceProvider>
                    </InvoiceServiceProvider>
                  </SpendPlanDocumentServiceProvider>
                </SpendPlanTemplateServicesProvider>
              </WorkPlanDocumentServiceProvider>
            </WorkPlanTemplateServicesProvider>
          </CpgServiceProvider>
        </CpgTemplateServiceProvider>
      </CableProvider>
    </IamServiceProvider>
  );
}
