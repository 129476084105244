import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const FileDownloadPage = lazy(
  () => import('../pages/file-download/FileDownloadPage')
);

export default function FileDownloadRoutes() {
  return (
    <Routes>
      <Route path="" element={<FileDownloadPage />} />
    </Routes>
  );
}
