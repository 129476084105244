import { useCommonServices } from '@/services/CommonServicesProvider';
import { useIamService } from '@/features/iam/services/useIamService';
import IconButton from '@/components/IconButton';
import {
  downloadTextFile,
  extractFileName,
} from '@/features/file-download/utils/file-download-utils';
import { OrganizationType } from '@/features/iam/types';
import DropdownButton from '@/components/DropdownButton';

interface Props {
  organizationId: string;
  organizationType: OrganizationType;
}

export default function DownloadOrganizationUsersButton({
  organizationId,
  organizationType,
}: Props) {
  const { toastService } = useCommonServices();
  const iamService = useIamService();

  function onDownloadOrganizationUsers(includeChildren: boolean) {
    toastService.processing(() =>
      iamService
        .exportOrganizationUsers(organizationId, includeChildren)
        .then((response) => {
          const fileName = extractFileName(response);
          downloadTextFile(response.data, fileName, 'text/csv;charset=utf-8;');
        })
    );
  }

  const options = [
    {
      text: 'State users',
      onClick: () => onDownloadOrganizationUsers(false),
    },
    {
      text: 'State and local users',
      onClick: () => onDownloadOrganizationUsers(true),
    },
  ];

  return (
    <>
      {organizationType == 'STATE' ? (
        <DropdownButton title="Download" options={options} />
      ) : (
        <IconButton
          icon="download-icon my-2"
          onClick={() => onDownloadOrganizationUsers(false)}
          small
        />
      )}
    </>
  );
}
