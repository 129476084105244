import { useParams } from 'react-router-dom';
import Content from './Content';
import { useIamService } from '@/features/iam/services/useIamService';
import AsyncComponent from '@/components/AsyncComponent';

export default function OrganizationUsersTable() {
  const iamService = useIamService();

  const { organizationId } = useParams();

  if (!organizationId) {
    throw new Error('Organization ID should be present');
  }

  return (
    <AsyncComponent
      queryKey={['loading organization users', organizationId]}
      fetch={() =>
        Promise.all([
          iamService.getOrganizationById(organizationId),
          iamService.getOrganizationUsers(organizationId),
        ])
      }
      builder={(data, refetch) => (
        <Content
          organizationUsersResponse={data[1]}
          organizationResponse={data[0]}
          refetchOrganizationsUsers={refetch}
        />
      )}
    />
  );
}
