import toast from 'react-hot-toast';

class ToastService {
  async loading<T>(call: () => Promise<T>): Promise<T> {
    const toastId = toast.loading('Processing...');
    const result = await call().catch((error) => {
      toast.remove(toastId);

      throw error;
    });
    toast.remove(toastId);
    return result;
  }

  async processing<T>(
    call: () => Promise<T>,
    displayErrorMessage?: (error) => boolean
  ): Promise<T> {
    return toast.promise(call(), {
      loading: 'Processing...',
      success: 'Successful',
      error: (e) => {
        let errorMessage;
        if (displayErrorMessage && displayErrorMessage(e)) {
          const data = e?.response?.data;

          errorMessage =
            typeof data === 'string' || data instanceof String
              ? data
              : data?.error;
        }

        return errorMessage ?? 'Could not process';
      },
    });
  }

  async processingTopRight<T>(call: () => Promise<T>): Promise<T> {
    return toast.promise(
      call(),
      {
        loading: '',
        success: '',
        error: 'Could not process',
      },
      { position: 'top-right' }
    );
  }

  error(message: string, duration?: number): void {
    toast.error(message, { duration });
  }

  success(message: string, duration?: number): void {
    toast.success(message, { duration });
  }
}

export default ToastService;
