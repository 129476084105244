import StorageService from '@/services/StorageService';
import { Component } from 'react';
import axios from 'axios';

const BASE_URL = import.meta.env.VITE_BASE_URL;

const loginPath = '/login';
const homePath = '/';
const loginEndpoint = '/iam/users/sign_in';

class NetworkService extends Component {
  // TODO: Can we use useCommonServices?
  static storage = new StorageService();

  static redirectPathParam = 'redirect-path';

  static init() {
    axios.defaults.baseURL = BASE_URL;

    axios.interceptors.request.use(function (config) {
      const token = NetworkService.storage.getBearerToken();
      const isLoginEndpoint = config.url?.includes(loginEndpoint);

      if (token && !isLoginEndpoint) {
        config.headers.Authorization = token;
      }

      return config;
    });

    axios.interceptors.response.use(
      (response) => {
        if (response?.headers?.authorization) {
          const token = response.headers.authorization.replace('Bearer ', '');
          this.storage.setToken(token);
        }

        return response;
      },
      (error) => {
        const statusCode = error?.response?.status;

        if (statusCode === 401) {
          this.storage.clear();
        }

        if (error?.response?.headers?.authorization) {
          const token = error.response.headers.authorization.replace(
            'Bearer ',
            ''
          );
          this.storage.setToken(token);
        }

        const location = window.location;

        if (statusCode === 401 && !location.href.includes(loginPath)) {
          const currentPath = encodeURIComponent(
            location.pathname + location.search
          );
          window.location.href = `${loginPath}/?${NetworkService.redirectPathParam}=${currentPath}`;
        } else if (
          statusCode === 403 &&
          window.location.pathname !== homePath
        ) {
          window.location.href = homePath;
        } else {
          return Promise.reject(error);
        }
      }
    );
  }
}

export default NetworkService;
