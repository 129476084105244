import { lazy } from 'react';

const OrganizationUser = lazy(
  () =>
    import('../pages/organization-user-settings/OrganizationUserSettingsPage')
);

const OrganizationUserRoutes = () => <OrganizationUser />;

export default OrganizationUserRoutes;
