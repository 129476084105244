import TextInput from '@/components/inputs/TextInput';
import { useCommonServices } from '@/services/CommonServicesProvider';
import { FormEvent, useState } from 'react';
import { useIamService } from '@/features/iam/services/useIamService';
import { isValidEmail } from '@/utils/validation-utils';
import UnathenticatedBodyView from '@/layouts/UnauthenticatedBodyView';

export default function ForgotPasswordPage() {
  const iam = useIamService();
  const { toastService } = useCommonServices();

  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  function resetState() {
    setEmail('');
    setSubmitted(false);
  }

  const isValid = isValidEmail(email);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isValid) {
      toastService.processing(
        () =>
          iam.requestPasswordReset(email).then((result) => {
            resetState();
            window.location.href = '/login';

            return result;
          }),
        (error) => error?.response.status == 422
      );
    } else {
      setSubmitted(true);
      toastService.error('Please fill required fields');
    }
  };

  return (
    <UnathenticatedBodyView
      title="Password reset"
      subTitle="Enter your email to request password reset"
      formConfig={{ onSubmit, mainButtonTitle: 'Request password reset' }}
    >
      <TextInput
        headerName="Email"
        headerClassName="text-white"
        value={email}
        placeholder="Email"
        type="email"
        required
        onChange={(email) => {
          setEmail(email.target.value);
        }}
        isValid={!submitted || isValid}
      />
    </UnathenticatedBodyView>
  );
}
