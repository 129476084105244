import { ReactNode } from 'react';

type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'text';

interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: ButtonType;
  disabled?: boolean;
  children?: ReactNode;
  submit?: boolean;
  textColor?: string;
  bgColor?: string;
  small?: boolean;
  fullWidth?: boolean;
  bold?: boolean;
  form?: string;
  tabIndex?: number;
}

const typeClasses: Map<ButtonType, string> = new Map([
  ['primary', 'rounded-full hover:bg-dark-purple-base disabled:bg-grey-400'],
  [
    'secondary',
    'border border-purple-30 bg-purple-40 rounded-full hover:bg-dark-purple-base hover:text-white',
  ],
  ['tertiary', 'rounded-full border border-light-grey hover:bg-purple-40'],
  ['text', 'disabled:text-grey-30'],
]);

const bgColorClasses: Map<ButtonType, string> = new Map([
  ['primary', 'bg-purple'],
  ['secondary', 'bg-purple-40'],
  ['tertiary', 'bg-white'],
  ['text', ''],
]);

const textColorClasses: Map<ButtonType, string> = new Map([
  ['primary', 'text-white'],
  ['secondary', 'text-purple'],
  ['tertiary', ''],
  ['text', 'text-deep-blue'],
]);

export default function Button({
  onClick,
  disabled = false,
  type = 'primary',
  children,
  submit = false,
  textColor,
  bgColor,
  small = false,
  fullWidth = false,
  bold = false,
  form = undefined,
  tabIndex,
}: Props) {
  const paddings = type === 'text' ? '' : small ? 'py-1 px-5' : 'py-2.5 px-5';
  const width = fullWidth ? 'w-full' : '';
  const weight = bold ? 'font-semibold' : 'font-normal';
  const tColor = textColor ?? textColorClasses.get(type);
  const bColor = bgColor ?? bgColorClasses.get(type);

  const finalClass = `${paddings} ${width} ${weight} ${tColor} ${bColor} ${typeClasses.get(
    type
  )}`;

  return (
    <button
      tabIndex={tabIndex}
      disabled={disabled}
      onClick={onClick}
      className={finalClass}
      type={submit ? 'submit' : 'button'}
      form={submit ? form : undefined}
    >
      {children}
    </button>
  );
}
