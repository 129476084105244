import { toDateWithoutTime } from '@/utils/date-utils';
import { Row } from 'react-table';

interface UserSort {
  values: Record<string, string>;
}

interface SortByFn<T> {
  (rowA: T, rowB: T, columnId: string): number;
}

interface SortTypes {
  [key: string]: SortByFn<UserSort>;
}

export const sortTypes: SortTypes = {
  string: (rowA: UserSort, rowB: UserSort, columnId: string) => {
    const [a, b] = [rowA.values[columnId], rowB.values[columnId]] as [
      string,
      string,
    ];

    return (a ?? '').localeCompare(b ?? '', 'en');
  },
};

export function sortByMultiple<T extends object>(
  ...sorters: SortByFn<Row<T>>[]
): SortByFn<Row<T>> {
  return (rowA, rowB) => {
    for (const sorter of sorters) {
      const result = sorter(rowA, rowB, '');

      if (result !== 0) {
        return result;
      }
    }

    return 0;
  };
}

export function sortByObjectStringProperty<T extends object>(
  property: string
): SortByFn<Row<T>> {
  return (rowA, rowB) => {
    return (rowA.original[property] as string).localeCompare(
      rowB.original[property],
      'en'
    );
  };
}

export function sortByObjectDateProperty<T extends object>(
  property: string
): SortByFn<Row<T>> {
  return (rowA, rowB) => {
    const dateA = toDateWithoutTime(rowA.original[property]);
    const dateB = toDateWithoutTime(rowB.original[property]);

    return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
  };
}

export function sortByObjectNumberProperty<T extends object>(
  property: string
): SortByFn<Row<T>> {
  return (rowA, rowB) => {
    return (
      ((rowA.original[property] as number) ?? 0) -
      (rowB.original[property] ?? 0)
    );
  };
}

export function sortByObjectNumberAccessorProperty<T extends object>(
  accessor: (rowValue: T) => number
): SortByFn<Row<T>> {
  return (rowA, rowB) => {
    const valueA = accessor(rowA.original) as number;
    const valueB = accessor(rowB.original) as number;

    return valueA - valueB;
  };
}
