import Button from '@/components/Button';
import { FormEvent, ReactNode } from 'react';

interface FormConfigSecondaryButtonProps {
  title: string;
  onClick: () => void;
}

interface FormConfigProps {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  mainButtonTitle: string;
  secondaryButton?: FormConfigSecondaryButtonProps;
  autoComplete?: string;
}

interface Props {
  title: string;
  subTitle: string;
  children?: ReactNode;
  formConfig?: FormConfigProps;
}

export default function UnathenticatedBodyView({
  title,
  subTitle,
  children,
  formConfig,
}: Props) {
  const body = formConfig ? (
    <form
      onSubmit={formConfig.onSubmit}
      className="w-[25vw]"
      autoComplete={formConfig.autoComplete}
    >
      {children}
      <div className="flex w-full items-end justify-between">
        {formConfig.secondaryButton && (
          <div className="m-3 flex-1">
            <Button
              type="text"
              textColor="text-white"
              onClick={formConfig.secondaryButton.onClick}
            >
              {formConfig.secondaryButton.title}
            </Button>
          </div>
        )}

        <div className="mt-7 flex-1">
          <Button submit type="tertiary" textColor="text-blue" fullWidth bold>
            {formConfig.mainButtonTitle}
          </Button>
        </div>
      </div>
    </form>
  ) : (
    children
  );

  return (
    <>
      <div className="pb-8 text-center">
        <h1 className="mb-2 text-base-xxl">{title}</h1>
        <p className="text-base-lg">{subTitle}</p>
      </div>
      {body}
    </>
  );
}
