import AwardOverviewRoutes from '@/features/awards/routes/AwardOverviewRoutes';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const SpendPlanTemplates = lazy(() => import('../pages/spend-plan-templates'));
const SpendPlanTemplate = lazy(
  () => import('../pages/spend-plan-template-flow')
);

const SpendPlanTemplatesRoutes = () => (
  <Routes>
    <Route path="" element={<SpendPlanTemplates />} />
    <Route path="/award-overview/*" element={<AwardOverviewRoutes />} />
    <Route path="/:spendPlanTemplateId" element={<SpendPlanTemplate />} />
  </Routes>
);

export default SpendPlanTemplatesRoutes;
