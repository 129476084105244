import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import AwardOverviewRoutes from './AwardOverviewRoutes';

const AwardSummary = lazy(() => import('../pages/summary/AwardsSummaryPage'));

const AwardSummaryRoutes = () => (
  <Routes>
    <Route path="" element={<AwardSummary />} />
    <Route path="/award-overview/*" element={<AwardOverviewRoutes />} />
  </Routes>
);

export default AwardSummaryRoutes;
