class LogService {
  info(message: string): void {
    console.info(message);
  }

  error(message: string, error?: any, componentStack?: string): void {
    console.error(message, error, componentStack);
  }
}

export default LogService;
