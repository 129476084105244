import { ReactNode, createContext, useContext } from 'react';
import { WorkPlanTemplateService } from './WorkPlanTemplateService';
import { WorkPlanTemplatePlannedActivityService } from './WorkPlanTemplatePlannedActivityService';
import { WorkPlanTemplateFophObjectiveService } from './WorkPlanTemplateFophObjectiveService';
import { WorkPlanTemplateFormInputService } from './WorkPlanTemplateFormInputService';

interface WorkPlanTemplateServices {
  workPlanTemplateService: WorkPlanTemplateService;
  workPlanTemplatePlannedActivityService: WorkPlanTemplatePlannedActivityService;
  workPlanTemplateFophObjectveService: WorkPlanTemplateFophObjectiveService;
  workPlanTemplateFormInputService: WorkPlanTemplateFormInputService;
}

const ServiceContext = createContext<WorkPlanTemplateServices>({
  workPlanTemplateService: new WorkPlanTemplateService(),
  workPlanTemplatePlannedActivityService:
    new WorkPlanTemplatePlannedActivityService(),
  workPlanTemplateFophObjectveService:
    new WorkPlanTemplateFophObjectiveService(),
  workPlanTemplateFormInputService: new WorkPlanTemplateFormInputService(),
});

export function WorkPlanTemplateServicesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const workPlanTemplateService = new WorkPlanTemplateService();
  const workPlanTemplatePlannedActivityService =
    new WorkPlanTemplatePlannedActivityService();
  const workPlanTemplateFophObjectveService =
    new WorkPlanTemplateFophObjectiveService();
  const workPlanTemplateFormInputService =
    new WorkPlanTemplateFormInputService();

  return (
    <ServiceContext.Provider
      value={{
        workPlanTemplateService,
        workPlanTemplatePlannedActivityService,
        workPlanTemplateFophObjectveService,
        workPlanTemplateFormInputService,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
}

export function useWorkPlanServices(): WorkPlanTemplateServices {
  return useContext(ServiceContext);
}
