export type LoaderType = 'sidebar' | 'default' | 'small' | 'tiny';

const Loader = ({ type = 'default' }: { type?: LoaderType }) => {
  if (type === 'sidebar') {
    return (
      <div className="h-screen w-1/5 border-r-0.5 border-r-grey-20 bg-white">
        <div className="flex items-center justify-center pt-32">
          <p>Loading ...</p>
        </div>
      </div>
    );
  }

  if (type === 'default') {
    return (
      <div className="m-6 flex flex-col items-center justify-center pt-40">
        <p className="pb-5">Please wait..</p>
        <p className="loader" />
      </div>
    );
  }

  if (type === 'small') {
    return (
      <div className="m-2 flex flex-col items-center justify-center">
        <p className="loader" style={{ width: '60px', height: '60px' }} />
      </div>
    );
  }

  if (type === 'tiny') {
    return (
      <div className="m-2 flex flex-col items-center justify-center">
        <p className="loader" style={{ width: '20px', height: '20px' }} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <p>Please wait..</p>
    </div>
  );
};

export default Loader;
