import axios from 'axios';
import {
  FophObjective,
  FophObjectiveProgressUpdate,
  FophObjectiveResponse,
} from '../types';

export abstract class FophObjectiveService {
  basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  async getFophObjectives(documentId: string): Promise<FophObjectiveResponse> {
    const response = await axios.get(
      `${this.basePath}/${documentId}/foph_objectives`
    );

    return response.data;
  }

  async editFophObjective(
    documentId: string,
    editedFophObjective: FophObjective
  ): Promise<FophObjective> {
    const { id, comments, progressUpdates, updatedAt, ...editPayload } =
      editedFophObjective;

    const response = await axios.put(
      `${this.basePath}/${documentId}/foph_objectives/${id}`,
      {
        foph_objective: {
          ...editPayload,
        },
      }
    );

    return response.data;
  }

  async editFophObjectiveProgressUpdate(
    documentId: string,
    fophObjectiveId: string,
    editedFophObjectiveProgressUpdate: FophObjectiveProgressUpdate
  ): Promise<FophObjectiveProgressUpdate> {
    const { id, ...editPayload } = editedFophObjectiveProgressUpdate;

    const response = await axios.put(
      `${this.basePath}/${documentId}/foph_objectives/${fophObjectiveId}/foph_objective_progress_updates/${id}`,
      {
        foph_objective_progress_update: {
          ...editPayload,
        },
      }
    );

    return response.data;
  }

  async addFophObjectiveComment(
    documentId: string,
    fophObjectiveId: string,
    content = ''
  ): Promise<FophObjective> {
    const response = await axios.post(
      `${this.basePath}/${documentId}/foph_objectives/${fophObjectiveId}/comment`,
      { comment: { content } }
    );

    return response.data;
  }
}
