export const isValidName = (name?: string | null): boolean =>
  name != null && name.trim() !== '';

export const isValidEmail = (email: string): boolean =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const isValidPhoneNumber = (phoneNumber: string): boolean =>
  /^\d{3}-\d{3}-\d{4}$/.test(phoneNumber);

export const isValidUserPhoneNumber = (phoneNumber: string): boolean => {
  const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');

  return /^\d{10}$/.test(formattedPhoneNumber);
};

export function isValidNumber(n: number | null) {
  return n != null && Number.isFinite(n);
}

export function extractNumber(n: string | number | null): number | null {
  const parsedNumber =
    n == null ? null : typeof n === 'number' ? n : parseFloat(n);

  return isValidNumber(parsedNumber) ? parsedNumber : null;
}

export function extractInteger(n: string): number | null {
  const parsedNumber = parseInt(n);

  return isValidNumber(parsedNumber) ? parsedNumber : null;
}
