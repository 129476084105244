import { useSearchParams } from 'react-router-dom';
import AsyncComponent from '@/components/AsyncComponent';
import { useIamService } from '@/features/iam/services/useIamService';
import Content from './Content';

export default function AcceptInvitationPage() {
  const iam = useIamService();

  const invitationToken = useSearchParams()[0].get(
    'invitation_token'
  ) as string;

  const checkInvitationStatus = () => {
    return iam
      .checkInvitationStatus(invitationToken)
      .then((result) => {
        return { email: result.email, invitationExpired: false };
      })
      .catch((error) => {
        if (error.response.status === 410) {
          return { email: '', invitationExpired: true };
        }
        throw error;
      });
  };

  return (
    <AsyncComponent
      queryKey={`data for accept invitation page for token ${invitationToken}`}
      fetch={checkInvitationStatus}
      builder={(data) => (
        <Content
          email={data.email}
          invitationExpired={data.invitationExpired}
          invitationToken={invitationToken}
        />
      )}
    />
  );
}
