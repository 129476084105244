import { ReactNode, createContext, useContext } from 'react';
import ConfigService from '.';

const ServiceContext = createContext(new ConfigService());

export function ConfigServiceProvider({ children }: { children: ReactNode }) {
  const configService = new ConfigService();

  return (
    <ServiceContext.Provider value={configService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useConfigService(): ConfigService {
  return useContext(ServiceContext);
}
