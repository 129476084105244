import { Tab, TabList } from '@fluentui/react-components';
import { Fragment } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';

const OrganizationSettingsLayoutRoute = () => {
  const { organizationId } = useParams();

  return (
    <Fragment>
      <div className="my-3">
        <TabList
          style={{ backgroundColor: '#f3f3f3' }}
          defaultSelectedValue="settings"
        >
          <Link to={`/organizations/${organizationId}`}>
            <Tab value="settings">Organization settings</Tab>
          </Link>
          <Link to={`/organizations/${organizationId}/roles`}>
            <Tab value="roles">Roles</Tab>
          </Link>
          <Link to={`/organizations/${organizationId}/users`}>
            <Tab value="team-members">Team members</Tab>
          </Link>
        </TabList>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default OrganizationSettingsLayoutRoute;
