import { ReactNode, createContext, useContext } from 'react';
import { AccountSettingsService } from '.';

const ServiceContext = createContext<AccountSettingsService>(
  new AccountSettingsService()
);

export function AccountSettingsServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const accountSettingsService = new AccountSettingsService();

  return (
    <ServiceContext.Provider value={accountSettingsService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useAccountSettingsService(): AccountSettingsService {
  return useContext(ServiceContext);
}
