import { ReactNode, createContext, useContext } from 'react';
import { InvoiceService } from '.';

const ServiceContext = createContext<InvoiceService>(new InvoiceService());

export function InvoiceServiceProvider({ children }: { children: ReactNode }) {
  const invoiceService = new InvoiceService();

  return (
    <ServiceContext.Provider value={invoiceService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useInvoiceService(): InvoiceService {
  return useContext(ServiceContext);
}
