import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const FileStoragePage = lazy(
  () => import('../pages/file-storage/FileStoragePage')
);

export default function FileStorageRoutes() {
  return (
    <Routes>
      <Route path="" element={<FileStoragePage />} />
    </Routes>
  );
}
