import { useEffect, useState } from 'react';
import Menu, { Item as MenuItem } from 'rc-menu';
import Dropdown from 'rc-dropdown';
import { SelectInfo } from 'rc-menu/lib/interface';
import TextInput from './TextInput';
import SelectDropdownPill, { PillsConfig } from './SelectDropdownPill';
import Checkbox from './Checkbox';

export const booleanDropdownElements: DropdownElement<boolean>[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export interface DropdownElement<T> {
  label: string;
  value: T;
  disabled?: boolean;
}

const emptyMenu = (
  <Menu multiple={false} onSelect={() => {}}>
    <MenuItem key={`Empty menu item`}>
      <label className="text-base-sm font-medium text-grey-400">
        No results found
      </label>
    </MenuItem>
  </Menu>
);

const clearDropdownKey = 'CLEAR_DROPDOWN_KEY';

interface Props<T> {
  className?: string;
  name: string;
  headerName?: string;
  elements: DropdownElement<T>[];
  defaultSelectedValues?: T[];
  compare?: (f: T, s: T) => boolean;
  onSelect: (element: T[]) => void;
  isMulti?: boolean;
  withSearchIcon?: boolean;
  disabled?: boolean;
  overrideSelectedName?: boolean;
  pillsConfig?: PillsConfig<T>;
  valid?: boolean;
  clearable?: boolean;
}

const SelectDropdown = <T,>({
  className,
  name,
  headerName,
  elements,
  defaultSelectedValues,
  compare = (f: T, s: T) => f === s,
  onSelect,
  isMulti,
  withSearchIcon = false,
  disabled = false,
  overrideSelectedName = false,
  pillsConfig,
  valid = true,
  clearable = false,
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedName, setSelectedName] = useState(name);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const hasAnyItems = elements.length > 0;

  useEffect(() => {
    setSelectedName(
      overrideSelectedName
        ? name
        : selectedKeys.map((index) => elements[index].label).join(', ') || name
    );
  }, [selectedKeys]);

  useEffect(() => {
    if (defaultSelectedValues == null) {
      if (elements.length === 0) {
        setSelectedKeys([]);
      }
      return;
    }
    setSelectedKeys(
      defaultSelectedValues
        .map((value) =>
          elements.find((element) => compare(element.value, value))
        )
        .filter((item) => !!item)
        .map((element) => `${elements.indexOf(element!)}`)
    );
  }, [defaultSelectedValues]);

  function onSelectOrDeselect(keys: string[]) {
    if (keys.includes(clearDropdownKey)) {
      setSelectedKeys([]);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Clearable dropdowns must accept nullable type
      onSelect([null]);
      return;
    }

    const uniqueSelectedKeys = [...new Set(keys)];
    setSelectedKeys(uniqueSelectedKeys);
    onSelect(uniqueSelectedKeys.map((index) => elements[index].value));
  }

  const onSelectOrDeselectEvent = ({ selectedKeys: keys }: SelectInfo) => {
    return onSelectOrDeselect(keys);
  };

  const cursor = (element: DropdownElement<T>) =>
    element.disabled ? 'cursor-not-allowed' : 'cursor-pointer';

  const menu = (
    <Menu
      multiple={isMulti}
      onSelect={onSelectOrDeselectEvent}
      onDeselect={onSelectOrDeselectEvent}
      selectedKeys={selectedKeys}
      disabled={disabled}
      onClick={(i) => {
        if (isMulti) {
          i.domEvent.stopPropagation();
        }
      }}
    >
      {clearable && (
        <MenuItem
          key={clearDropdownKey}
          className={`flex cursor-pointer items-center justify-center`}
        >
          <span className="close-icon mt-1.5" />
          <p className="font-normal">Clear selection</p>
        </MenuItem>
      )}
      {elements.map((element, index) => (
        <MenuItem
          key={index}
          disabled={element.disabled}
          className={`${cursor(element)} flex items-center`}
        >
          {isMulti && (
            <div className="mr-2">
              <Checkbox
                id={`Select dropdown checkbox ${index} ${name} ${element.label} ${element.value}`}
                isDisabled={element.disabled}
                isChecked={selectedKeys.includes(`${index}`)}
              />
            </div>
          )}
          {element.label}{' '}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div>
      {headerName && (
        <p className="mb-1 mt-6 text-base-sm font-semibold text-grey-black">
          {headerName}
        </p>
      )}
      {disabled ? (
        <TextInput
          className="max-w-[31.625rem] cursor-not-allowed"
          placeholder={selectedName}
          value={selectedName}
          onChange={() => {}}
          disabled
        />
      ) : (
        <Dropdown
          trigger={['click']}
          overlay={hasAnyItems ? menu : emptyMenu}
          animation="slide-up"
          onVisibleChange={setIsOpen}
          onOverlayClick={() => setIsOpen(false)}
        >
          <div
            className={`${className} text-sm flex h-fit flex-nowrap rounded-lg border border-light-grey bg-white p-2 font-medium text-grey-30 ${className} ${
              valid ? '' : 'invalid-input'
            }`}
            role="button"
          >
            <div className="flex grow">
              {withSearchIcon && <span className="action-search-icon mr-1" />}
              <p
                className={
                  selectedName === name ? 'text-grey-30' : 'text-grey-black'
                }
              >
                {selectedName}
              </p>
            </div>
            <span
              className={`float-right mt-1.5 ml-1 ${
                isOpen ? 'dropdown-icon--closed' : 'dropdown-icon--open'
              }`}
            />
          </div>
        </Dropdown>
      )}
      {pillsConfig != null && (
        <div className="mt-2 flex flex-row flex-wrap gap-y-2">
          {selectedKeys.map((key) => {
            const element: DropdownElement<T> = elements[key];

            return (
              <SelectDropdownPill
                key={element.label}
                element={element}
                pillsConfig={pillsConfig}
                onRemove={() => {
                  onSelectOrDeselect(selectedKeys.filter((k) => k !== key));
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;
