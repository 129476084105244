import { useCommonServices } from '@/services/CommonServicesProvider';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function SignInAsRedirect() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { storageService } = useCommonServices();

  const token = searchParams.get('token') ?? undefined;

  useEffect(() => {
    if (token != null) {
      storageService.setToken(token);
      searchParams.delete('token');
      setSearchParams(searchParams);
      window.location.pathname = '/';
    }
  }, []);

  return <></>;
}
