import useModalController from '@/components/modals/useModalController';
import AddUserModal from './AddUserModal';
import {
  OrganizationRegion,
  OrganizationType,
  Role,
} from '@/features/iam/types';
import Button from '@/components/Button';

interface Props {
  possibleRoles: Role[];
  availableRegions: OrganizationRegion[];
  refetchOrganizationsUsers: () => void;
  organizationType: OrganizationType;
  canSetAdmin: boolean;
  availableSeats: number;
  stateContactEmail?: string;
}

export default function AddUserButton({
  possibleRoles,
  availableRegions,
  refetchOrganizationsUsers,
  organizationType,
  canSetAdmin,
  availableSeats,
  stateContactEmail,
}: Props) {
  const addController = useModalController();

  function onPressed() {
    addController.open();
  }

  return (
    <div className="grid justify-items-end">
      <Button type="secondary" onClick={onPressed}>
        <div className="flex items-end">
          <span className="plus-icon mr-2" />
          Add new user
        </div>
      </Button>

      <AddUserModal
        controller={addController}
        possibleRoles={possibleRoles}
        availableRegions={availableRegions}
        organizationType={organizationType}
        refetchOrganizationsUsers={refetchOrganizationsUsers}
        canSetAdmin={canSetAdmin}
        availableSeats={availableSeats}
        stateContactEmail={stateContactEmail}
      />
    </div>
  );
}
