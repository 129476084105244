import axios from 'axios';
import { ReviewComment } from '../types';

export class CommentService {
  async deleteReviewComment(commentId: string): Promise<void> {
    return axios.delete(`/comments/${commentId}`);
  }

  async editReviewComment(
    commentId: string,
    content: string
  ): Promise<ReviewComment> {
    const response = await axios.put(`/comments/${commentId}`, {
      comment: { content },
    });

    return response.data;
  }

  async resolveReviewComment(commentId: string): Promise<ReviewComment> {
    const response = await axios.post(`/comments/${commentId}/resolve`);

    return response.data;
  }
}
