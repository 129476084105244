import { ReactNode } from 'react';
import Modal from '../modals/Modal';
import useModalController from '../modals/useModalController';
import { DropdownElement } from './SelectDropdown';

export interface PillsConfig<T> {
  allowRemoving?: (element: T) => boolean;
  onClick?: (element: T) => void;
  modalBuilder?: (element: T) => ReactNode;
}

export default function SelectDropdownPill<T>({
  element,
  onRemove,
  pillsConfig,
}: {
  element: DropdownElement<T>;
  onRemove: () => void;
  pillsConfig: PillsConfig<T>;
}) {
  const modalController = useModalController();

  const canRemove =
    pillsConfig.allowRemoving && pillsConfig.allowRemoving(element.value);

  const onClick = pillsConfig.onClick
    ? pillsConfig.onClick
    : pillsConfig.modalBuilder
      ? modalController.open
      : () => null;

  const onClickClasses =
    pillsConfig.onClick || pillsConfig.modalBuilder
      ? 'cursor-pointer underline'
      : '';

  return (
    <>
      <div
        onClick={() => onClick(element.value)}
        className={`mr-2 flex items-center rounded-full bg-pale-grey p-2 text-base-m font-medium ${onClickClasses}`}
      >
        {element.label}

        {canRemove && (
          <span
            className="close-icon mt-2 ml-2 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
          />
        )}
      </div>
      <Modal controller={modalController}>
        {pillsConfig.modalBuilder
          ? pillsConfig.modalBuilder(element.value)
          : null}
      </Modal>
    </>
  );
}
