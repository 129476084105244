import { ReactNode, createContext, useContext } from 'react';
import IamService from '.';

const ServiceContext = createContext(new IamService());

export function IamServiceProvider({ children }: { children: ReactNode }) {
  const iamService = new IamService();

  return (
    <ServiceContext.Provider value={iamService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useIamService(): IamService {
  return useContext(ServiceContext);
}
