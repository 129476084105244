import Button from '@/components/Button';
import UnathenticatedBodyView from '@/layouts/UnauthenticatedBodyView';
import { downloadTextFile } from '@/features/file-download/utils/file-download-utils';

export default function SaveBackupCodes({
  setCurrentStep,
  backupCodes,
}: {
  setCurrentStep: (step: number) => void;
  backupCodes: string[];
}) {
  const handleCopyAllCodes = () => {
    const allCodes = backupCodes.join('\n');
    navigator.clipboard.writeText(allCodes);
  };

  const handleDownloadTxtFile = () => {
    const allCodes = backupCodes.join('\n');
    downloadTextFile(allCodes, 'backup-codes.txt', 'text/plain');
  };

  return (
    <UnathenticatedBodyView
      title="Save Backup Codes"
      subTitle="With two-factor enabled for your account, you&rsquo;ll need these
  backup codes if you ever lose your device"
    >
      <div className="mb-3 rounded-sm bg-white p-4 text-grey-black">
        {backupCodes.map((code, index) => (
          <div className="m-2" key={index}>
            {code}
          </div>
        ))}
        <div className="flex items-center justify-center">
          <Button type="text" onClick={handleCopyAllCodes}>
            Copy
          </Button>

          <div className="mx-4" />

          <Button type="text" onClick={handleDownloadTxtFile}>
            Download
          </Button>
        </div>
      </div>
      <div className="flex justify-between" style={{ width: '300px' }}>
        <Button
          type="text"
          textColor="text-white"
          onClick={() => setCurrentStep(1)}
        >
          Back
        </Button>

        <div className="my-4">
          <Button type="secondary" onClick={() => setCurrentStep(3)}>
            Continue
          </Button>
        </div>
      </div>
    </UnathenticatedBodyView>
  );
}
