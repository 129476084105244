import { ReactNode, createContext, useContext } from 'react';
import { FileStorageService } from '.';

const ServiceContext = createContext<FileStorageService>(
  new FileStorageService()
);

export function FileStorageServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const fileStorageService = new FileStorageService();

  return (
    <ServiceContext.Provider value={fileStorageService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useFileStorageService(): FileStorageService {
  return useContext(ServiceContext);
}
