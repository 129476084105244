import { ReactNode, createContext, useContext } from 'react';
import { AnnouncementService } from '.';

const ServiceContext = createContext<AnnouncementService>(
  new AnnouncementService()
);

export function AnnouncementServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const announcementService = new AnnouncementService();

  return (
    <ServiceContext.Provider value={announcementService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useAnnouncementService(): AnnouncementService {
  return useContext(ServiceContext);
}
