import { differenceInMonths } from 'date-fns';
import dayjs from 'dayjs';

export const daysBetweenDates = (start: Date, end: Date): number =>
  dayjs(start).diff(dayjs(end), 'days');

export const isYearOutOfDate = (year: number, outOfDateYears = 3): boolean =>
  year >= new Date().getFullYear() - outOfDateYears;

export function toDateWithoutTime(date: string) {
  const utcDate = new Date(date);
  return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
}

export function monthDifferenceFromToday(month: number, year: number) {
  const today = new Date();
  const date = new Date(year, month - 1);

  return differenceInMonths(today, date);
}
