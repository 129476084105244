import DropdownMenu from '@/components/DropdownMenu';
import ProfileMenu from './ProfileMenu';
import { useIamService } from '@/features/iam/services/useIamService';
import { useCommonServices } from '@/services/CommonServicesProvider';
import { useQuery } from 'react-query';
import Loader from '../Loader';
import NotificationButton from './sidebar/NotificationButton';
import { useNavigate, Link } from 'react-router-dom';
import {
  QuestionMarkCircleIcon,
  Bars3Icon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import { useSidebarCollapsedStateDispatch } from './reducer/SidebarReducer';
import { getAdminPanelBaseUrl, openLink } from '@/utils/link-utils';
import Button from '../Button';

const Nav = () => {
  const iamService = useIamService();
  const { logService } = useCommonServices();
  const navigate = useNavigate();

  const sidebarCollapsedStateDispatch = useSidebarCollapsedStateDispatch();

  const {
    data: profile,
    isLoading,
    error,
  } = useQuery('me', () => iamService.getUserInformation(), {
    suspense: false,
    onError: (error: Error) => {
      logService.error(`Error fetching data:${error}`);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 895_000, // Refresh token every 15 minutes
  });

  if (!profile && !isLoading) {
    if (error) {
      throw error;
    }
    throw new Error('Data from query not defined');
  }

  const selectedProfileOrganization = profile?.organizations.find(
    (org) => org.id === profile.activeOrganizationId
  );

  const isSelectedOrganizationDemo =
    selectedProfileOrganization?.isDemo ?? false;

  const logoUrl = selectedProfileOrganization?.logoUrl;

  const helpLink = selectedProfileOrganization?.helpLink;

  const isPartOfGodOrganization = profile?.organizations.some(
    (org) => org.type === 'GOD'
  );

  return (
    <div className="w-full border-b-0.5 border-b-grey-20">
      <section className="x-auto">
        <nav className="flex max-h-16 items-center justify-between bg-white pl-5">
          <div
            onClick={() => sidebarCollapsedStateDispatch?.('toggle')}
            className="mr-5 flex cursor-pointer"
          >
            <Bars3Icon height="1.5rem" />
          </div>

          {logoUrl && (
            <div
              className="mr-5 mb-0.5 cursor-pointer"
              onClick={() => navigate('/')}
            >
              <img
                src={logoUrl}
                className="h-[4rem] w-[7rem]"
                alt="organization logo"
              />
            </div>
          )}
          <div
            className="flex grow cursor-pointer flex-col items-start py-2"
            onClick={() => navigate('/')}
          >
            <span className="powered-by-icon mt-0.5" />
            <span className="grant-ready-logo-icon" />
          </div>
          {helpLink && (
            <div className="m-auto flex items-center px-4">
              <Link to={helpLink}>
                <QuestionMarkCircleIcon className="h-6 w-6" />
              </Link>
            </div>
          )}
          {isPartOfGodOrganization && (
            <div className="m-auto flex items-center px-4">
              <Button
                type="text"
                textColor="text-black"
                onClick={() => openLink(getAdminPanelBaseUrl())}
              >
                <Cog6ToothIcon height="1.5rem" />
              </Button>
            </div>
          )}
          <div className="m-auto flex items-center px-4">
            {profile && <NotificationButton profile={profile} />}
          </div>
          <div className="ml-auto flex items-center border-l border-l-light-grey pl-4">
            <DropdownMenu
              overlay={
                isLoading ? <Loader /> : <ProfileMenu profile={profile} />
              }
              dropdownButtonBuilder={(isOpen) => (
                <div className="flex items-center" role="button">
                  <div className="mr-2 h-[3rem] rounded-full border border-grey-40 pb-2">
                    <span className="user-icon" />
                  </div>
                  <div className="mr-3 flex flex-col">
                    <span>{selectedProfileOrganization?.name}</span>
                    {isSelectedOrganizationDemo && (
                      <span className="badge-primary badge mt-1">DEMO</span>
                    )}
                  </div>
                  {isOpen ? (
                    <span className="dropdown-icon--closed float-right mt-1 ml-1" />
                  ) : (
                    <span className="dropdown-icon--open float-right mt-1 ml-1" />
                  )}
                </div>
              )}
            ></DropdownMenu>
          </div>
        </nav>
      </section>
    </div>
  );
};

export default Nav;
