import GetAuthenticator from './components/GetAuthenticator';
import EnableVerification from './components/EnableVerification';
import SetupAuthenticator from './components/SetupAuthenticator';
import { useState } from 'react';
import SaveBackupCodes from './components/SaveBackupCodes';

const TwoFactor = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [qrCode, setQrCode] = useState('');
  const [backupCodes, setBackupCodes] = useState<string[]>([]);

  return (
    <>
      {currentStep === 0 && (
        <GetAuthenticator
          setCurrentStep={setCurrentStep}
          setQrCode={setQrCode}
          setBackupCodes={setBackupCodes}
        />
      )}
      {currentStep === 1 && (
        <SetupAuthenticator setCurrentStep={setCurrentStep} qrCode={qrCode} />
      )}
      {currentStep === 2 && (
        <SaveBackupCodes
          setCurrentStep={setCurrentStep}
          backupCodes={backupCodes}
        />
      )}
      {currentStep === 3 && (
        <EnableVerification setCurrentStep={setCurrentStep} />
      )}
    </>
  );
};

export default TwoFactor;
