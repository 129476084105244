import { ReactNode, createContext, useContext } from 'react';
import { VersionService } from '.';

const ServiceContext = createContext<VersionService>(new VersionService());

export function VersionServiceProvider({ children }: { children: ReactNode }) {
  const versionService = new VersionService();

  return (
    <ServiceContext.Provider value={versionService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useVersionService(): VersionService {
  return useContext(ServiceContext);
}
