import { ReactNode, createContext, useContext } from 'react';
import { CpgService } from './CpgService';
import { CpgFormInputService } from './CpgFormInputService';

interface CpgServices {
  cpgService: CpgService;
  cpgFormInputService: CpgFormInputService;
}

const ServiceContext = createContext<CpgServices>({
  cpgService: new CpgService(),
  cpgFormInputService: new CpgFormInputService(),
});

export function CpgServiceProvider({ children }: { children: ReactNode }) {
  const cpgService = new CpgService();
  const cpgFormInputService = new CpgFormInputService();

  return (
    <ServiceContext.Provider value={{ cpgService, cpgFormInputService }}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useCpgServices(): CpgServices {
  return useContext(ServiceContext);
}
