import Loader from '@/components/Loader';
import { Suspense } from 'react';
import { Toaster } from 'react-hot-toast';

const AuthenticatedView = ({ children }) => (
  <>
    <Suspense fallback={<Loader />}>{children}</Suspense>
    <Toaster containerClassName={'mt-16'} />
  </>
);

export default AuthenticatedView;
