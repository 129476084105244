import { AllocationService } from '@/features/allocations/services';
import axios, { AxiosResponse } from 'axios';

export class SpendPlanTemplateAllocationService extends AllocationService {
  constructor() {
    super('/spend_plan_templates');
  }

  async getExport(templateId: string): Promise<AxiosResponse<string>> {
    const response = await axios.get(
      `spend_plan_templates/${templateId}/allocations/export`
    );
    return response;
  }

  async importAllocations(
    templateId: string,
    file: File
  ): Promise<AxiosResponse<string>> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(
      `spend_plan_templates/${templateId}/allocations/import`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response;
  }
}
