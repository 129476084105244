import NavigationLayoutRouter from '@/routes/NavigationLayoutRouter';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const PersonnelReportPage = lazy(() => import('../pages/PersonnelReportPage'));

export default function PersonnelReportRoutes() {
  return (
    <Routes>
      <Route element={<NavigationLayoutRouter />}>
        <Route path="" element={<PersonnelReportPage />} />
      </Route>
    </Routes>
  );
}
