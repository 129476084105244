import TextInput from '@/components/inputs/TextInput';
import { useCommonServices } from '@/services/CommonServicesProvider';
import { FormEvent, useState } from 'react';
import { useIamService } from '@/features/iam/services/useIamService';
import UnathenticatedBodyView from '@/layouts/UnauthenticatedBodyView';

export default function EnableVerification({
  setCurrentStep,
}: {
  setCurrentStep: (step: number) => void;
}) {
  const iam = useIamService();
  const { toastService } = useCommonServices();

  const [optAttempt, setOtpAttempt] = useState('');

  function resetState() {
    setOtpAttempt('');
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    iam
      .enable2fa(optAttempt)
      .then((result) => {
        window.location.href = '/';
        return result;
      })
      .catch((error) => {
        toastService.error(error.response.data.message);
        resetState();
      });
  };

  return (
    <UnathenticatedBodyView
      title="Enable 2-Step Verification"
      subTitle="Enter the 6-digit code generated by your Authenticator app to make
  sure it is configured correctly"
      formConfig={{
        onSubmit,
        mainButtonTitle: 'Submit',
        secondaryButton: { title: 'Back', onClick: () => setCurrentStep(2) },
        autoComplete: 'off',
      }}
    >
      <TextInput
        headerName="Verification code"
        headerClassName="text-white"
        value={optAttempt}
        placeholder="Verification code"
        autoComplete="off"
        required
        onChange={(otpAttempt) => {
          setOtpAttempt(otpAttempt.target.value);
        }}
      />
    </UnathenticatedBodyView>
  );
}
