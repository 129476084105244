import { useCommonServices } from '@/services/CommonServicesProvider';
import { useQuery } from 'react-query';
import Loader, { LoaderType } from '@/components/Loader';
import { ReactNode } from 'react';

interface Props<T> {
  queryKey: string | string[];
  fetch: () => Promise<T>;
  enabled?: boolean;
  builder: (data: T, refetch: () => void) => ReactNode;
  loaderType?: LoaderType;
  refetchOnWindowFocus?: boolean;
}

export default function AsyncComponent<T>({
  queryKey,
  fetch,
  builder,
  loaderType,
  refetchOnWindowFocus = false,
  enabled = true,
}: Props<T>) {
  const { logService } = useCommonServices();

  const { data, isLoading, refetch, error } = useQuery(queryKey, fetch, {
    suspense: false,
    onError: (error: Error) => {
      logService.error(`Error fetching data:${error}`);
    },
    refetchOnWindowFocus: refetchOnWindowFocus,
    enabled: enabled,
  });

  if (isLoading || !enabled) {
    return <Loader type={loaderType} />;
  }

  if (!data) {
    if (error) {
      throw error;
    }
    throw new Error('Data from query not defined');
  }

  return <>{builder(data, refetch)}</>;
}
