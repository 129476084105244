import { ReactNode, createContext, useContext } from 'react';
import { SpendPlanTemplateService } from './SpendPlanTemplateService';
import { SpendPlanTemplateAllocationService } from './SpendPlanTemplateAllocationService';

interface SpendPlanTemplateServices {
  spendPlanTemplateService: SpendPlanTemplateService;
  spendPlanTemplateAllocationService: SpendPlanTemplateAllocationService;
}

const ServiceContext = createContext<SpendPlanTemplateServices>({
  spendPlanTemplateService: new SpendPlanTemplateService(),
  spendPlanTemplateAllocationService: new SpendPlanTemplateAllocationService(),
});

export function SpendPlanTemplateServicesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const spendPlanTemplateService = new SpendPlanTemplateService();
  const spendPlanTemplateAllocationService =
    new SpendPlanTemplateAllocationService();

  return (
    <ServiceContext.Provider
      value={{ spendPlanTemplateService, spendPlanTemplateAllocationService }}
    >
      {children}
    </ServiceContext.Provider>
  );
}

export function useSpendPlanTemplateServices(): SpendPlanTemplateServices {
  return useContext(ServiceContext);
}
