import AwardOverviewRoutes from '@/features/awards/routes/AwardOverviewRoutes';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const WorkPlanDocuments = lazy(() => import('../pages/work-plan-documents'));
const WorkPlanTemplate = lazy(() => import('../pages/work-plan-document-flow'));

const WorkPlanDocumentsRoutes = () => (
  <Routes>
    <Route path="" element={<WorkPlanDocuments />} />
    <Route path="/award-overview/*" element={<AwardOverviewRoutes />} />
    <Route path="/:workPlanDocumentId" element={<WorkPlanTemplate />} />
  </Routes>
);

export default WorkPlanDocumentsRoutes;
