import { ReactNode, createContext, useContext } from 'react';
import { NotificationsService } from '.';

const ServiceContext = createContext(new NotificationsService());

export function NotificationsServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const notificationsService = new NotificationsService();

  return (
    <ServiceContext.Provider value={notificationsService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useNotificationsService(): NotificationsService {
  return useContext(ServiceContext);
}
