import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Notifications = lazy(() => import('../pages/notifications'));

const NotificationsRoutes = () => (
  <Routes>
    <Route path="" element={<Notifications />} />
  </Routes>
);

export default NotificationsRoutes;
