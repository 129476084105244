import { Outlet } from 'react-router-dom';

import AuthenticatedView from '@/layouts/AuthenticatedView';

const AuthenticatedLayoutRouter = () => (
  <AuthenticatedView>
    <Outlet />
  </AuthenticatedView>
);

export default AuthenticatedLayoutRouter;
