import { FormEvent, useState } from 'react';
import { useIamService } from '../../services/useIamService';
import { useCommonServices } from '@/services/CommonServicesProvider';
import { useSearchParams } from 'react-router-dom';
import NetworkService from '@/services/NetworkService';
import LoginCredentials from './components/LoginCredentials';
import OtpVerification from './components/OtpVerification';

export interface LoginValues {
  email: string;
  password: string;
  otpAttempt: string;
}

const initialValues: LoginValues = {
  email: '',
  password: '',
  otpAttempt: '',
};

const Login = () => {
  const iam = useIamService();
  const { toastService } = useCommonServices();
  const [values, setValues] = useState(initialValues);
  const [currentStep, setCurrentStep] = useState(0);

  const redirectPath = useSearchParams()[0].get(
    NetworkService.redirectPathParam
  ) as string;

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    toastService.loading(() =>
      iam
        .signIn(values.email, values.password, values.otpAttempt)
        .then(() => {
          window.location.href = redirectPath || '/';
        })
        .catch((error) => {
          if (error.response.status === 428) {
            return setCurrentStep(1);
          } else if (error.response.status === 412) {
            window.location.href = '/two_factor';
            return;
          }
          toastService.error(error.response.data.error);
        })
    );
  };

  return (
    <>
      {currentStep === 0 && (
        <LoginCredentials
          values={values}
          setValues={setValues}
          onSubmit={onSubmit}
        />
      )}
      {currentStep === 1 && (
        <OtpVerification
          values={values}
          setValues={setValues}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default Login;
