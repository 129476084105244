import { ReactNode, createContext, useContext } from 'react';
import { AwardDefinitionService } from './AwardDefinitionService';
import { AwardDefinitionFormInputService } from './AwardDefinitionFormInputService';

interface AwardDefinitionServices {
  awardDefinitionService: AwardDefinitionService;
  awardDefinitionFormInputService: AwardDefinitionFormInputService;
}

const ServiceContext = createContext<AwardDefinitionServices>({
  awardDefinitionService: new AwardDefinitionService(),
  awardDefinitionFormInputService: new AwardDefinitionFormInputService(),
});

export function AwardDefinitionServicesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const awardDefinitionService = new AwardDefinitionService();
  const awardDefinitionFormInputService = new AwardDefinitionFormInputService();

  return (
    <ServiceContext.Provider
      value={{
        awardDefinitionService,
        awardDefinitionFormInputService,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
}

export function useAwardDefinitionServices(): AwardDefinitionServices {
  return useContext(ServiceContext);
}
