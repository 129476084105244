import AwardOverviewRoutes from '@/features/awards/routes/AwardOverviewRoutes';
import ExpenditureReportRoutes from '@/features/expenditure-report/routes/ExpenditureReportRoutes';
import InvoicesRoutes from '@/features/invoices/routes/InvoicesRoutes';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const SpendPlanDocuments = lazy(() => import('../pages/spend-plan-documents'));
const SpendPlanDocument = lazy(
  () => import('../pages/spend-plan-document-flow')
);

const SpendPlanDocumentsRoutes = () => (
  <Routes>
    <Route path="" element={<SpendPlanDocuments />} />
    <Route path="/award-overview/*" element={<AwardOverviewRoutes />} />
    <Route path="/:spendPlanDocumentId" element={<SpendPlanDocument />} />
    <Route
      path="/:spendPlanDocumentId/invoices/*"
      element={<InvoicesRoutes />}
    />
    <Route
      path="/:spendPlanDocumentId/expenditure-report/*"
      element={<ExpenditureReportRoutes />}
    />
  </Routes>
);

export default SpendPlanDocumentsRoutes;
