import { TableInstance } from 'react-table';
import { AwardsSummary } from '@/features/awards/types';

const VISIBLE_PAGES_COUNT = 5;

export type PaginationProps = Pick<
  TableInstance<AwardsSummary>,
  'previousPage' | 'nextPage' | 'canPreviousPage' | 'canNextPage' | 'pageCount'
> & {
  pageIndex: number;
  gotoPage: (pageIndex: number) => void;
};

export default function Pagination({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
}: PaginationProps) {
  const pages = Array.from({ length: pageCount }, (_, i) => i);

  function getVisiblePages() {
    const response: number[] = [];

    const sidePagesCount = Math.floor(VISIBLE_PAGES_COUNT / 2);
    const rightPagesCount = Math.min(pageCount - 1 - pageIndex, sidePagesCount);

    const indexSubtract = VISIBLE_PAGES_COUNT - 1 - rightPagesCount;

    let i = pageIndex - indexSubtract;
    while (response.length < VISIBLE_PAGES_COUNT && i < pageCount) {
      if (i >= 0 && i < pageCount) {
        response.push(pages[i]);
      }
      i++;
    }

    return response;
  }

  return (
    <div className="pagination flex justify-start">
      <span className="pagination-box text-sm flex rounded-lg border border-[#DBDBDB]">
        <button
          className={`pl-1 ${
            canPreviousPage ? 'cursor-pointer' : 'cursor-default'
          }`}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <span
            className={`arrow-chevron-double-left block ${
              canPreviousPage ? 'bg-grey-black' : 'bg-grey-50'
            }`}
          />
        </button>
        <button
          className={`pagination-manage pr-1 ${
            canPreviousPage ? 'cursor-pointer' : 'cursor-default'
          }`}
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <span
            className={`arrow-chevron-left block ${
              canPreviousPage ? 'bg-grey-black' : 'bg-grey-50'
            }`}
          />
        </button>
        {getVisiblePages().map((page) => (
          <button
            className={`pagination-number cursor-pointer ${
              pageIndex === page
                ? 'bg-purple-40 font-semibold text-blue'
                : 'white font-normal text-grey-black'
            }`}
            key={page}
            onClick={() => gotoPage(page)}
          >
            {page + 1}
          </button>
        ))}

        <button
          className={`pagination-manage pl-1 ${
            canNextPage ? 'cursor-pointer' : 'cursor-default'
          }`}
          onClick={nextPage}
          disabled={!canNextPage}
        >
          <span
            className={`arrow-chevron-right block ${
              canNextPage ? 'bg-grey-black' : 'bg-grey-50'
            }`}
          />
        </button>
        <button
          className={`pagination-manage pr-1 ${
            canNextPage ? 'cursor-pointer' : 'cursor-default'
          }`}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <span
            className={`arrow-chevron-double-right block ${
              canNextPage ? 'bg-grey-black' : 'bg-grey-50'
            }`}
          />
        </button>
      </span>
    </div>
  );
}
