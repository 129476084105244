import AwardOverviewRoutes from '@/features/awards/routes/AwardOverviewRoutes';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const WorkPlanTemplates = lazy(() => import('../pages/work-plan-templates'));
const WorkPlanTemplate = lazy(() => import('../pages/work-plan-template-flow'));

const WorkPlanTemplatesRoutes = () => (
  <Routes>
    <Route path="" element={<WorkPlanTemplates />} />
    <Route path="/award-overview/*" element={<AwardOverviewRoutes />} />
    <Route path="/:workPlanTemplateId" element={<WorkPlanTemplate />} />
  </Routes>
);

export default WorkPlanTemplatesRoutes;
