import { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { useCommonServices } from '@/services/CommonServicesProvider';
import Button from '../Button';

function FallbackComponent() {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="mb-5 text-base-xxl font-bold">Something went wrong!</h1>
      <p className="mb-10 text-grey-base">
        Please refresh the page or go to the homepage.
      </p>

      <Button onClick={() => (window.location.pathname = '/')}>
        Go to the homepage
      </Button>
    </div>
  );
}

interface Props {
  children?: ReactNode;
}

export default function ErrorBoundry({ children }: Props) {
  const { logService } = useCommonServices();

  return (
    <Sentry.ErrorBoundary
      fallback={<FallbackComponent />}
      onError={(error, componentStack) =>
        logService.error(
          'Error Boundary caught an error:',
          error,
          componentStack
        )
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
