import NavigationLayoutRouter from '@/routes/NavigationLayoutRouter';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const ReportsTable = lazy(() => import('../pages/table/ReportsTablePage'));

const CpgReportDetails = lazy(
  () => import('../pages/cpg-report-details/CpgReportDetailsPage')
);

const ReportDetails = lazy(
  () => import('../pages/report-details/ReportDetailsPage')
);

const ReportTemplatesPage = lazy(
  () => import('../pages/report-templates/ReportTemplatesPage')
);

const ReportTemplatePage = lazy(
  () => import('../pages/report-template/ReportTemplatePage')
);

const ReportsRoutes = () => (
  <Routes>
    <Route element={<NavigationLayoutRouter />}>
      <Route path="" element={<ReportsTable />} />
      <Route path="/cpg-reports/:reportId" element={<CpgReportDetails />} />
    </Route>
    <Route path="/report-templates" element={<ReportTemplatesPage />} />
    <Route
      path="/report-templates/:reportTemplateId"
      element={<ReportTemplatePage />}
    />
    <Route path="/:reportId" element={<ReportDetails />} />
  </Routes>
);

export default ReportsRoutes;
