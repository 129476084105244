import axios, { AxiosResponse } from 'axios';
import StorageService from '@/services/StorageService';
import {
  OrganizationDetailed,
  OrganizationUser,
  Role,
  OrganizationUsersResponse,
  OrganizationsResponse,
  Profile,
  Setup2faResponse,
  RolesResponse,
  RoleResponse,
  RoleParams,
  OrganizationRegion,
  OrganizationRegionEditValue,
  RandomizePasswordResponse,
  CheckInvitationStatusResponse,
} from '@/features/iam/types';
import { FundingType } from '@/features/awards/types';
import { AddOrganizationFormValues } from '../pages/organizations-table/components/add_organization_flow/AddOrganizationModal';

export class IamService {
  // TODO: Can we use useCommonServices?
  storage = new StorageService();

  async checkInvitationStatus(
    invitationToken: string
  ): Promise<CheckInvitationStatusResponse> {
    const response = await axios.post('/iam/users/check_invitation_status', {
      invitationToken,
    });

    return response.data;
  }

  async setup2fa(): Promise<Setup2faResponse> {
    const response = await axios.post('/iam/users/setup_2fa');

    return response.data;
  }

  async signIn(email: string, password: string, otpAttempt?: string) {
    const response = await axios.post('/iam/account/sign_in', {
      user: { email, password, otpAttempt },
    });

    return response.data;
  }

  enable2fa(otpAttempt: string) {
    return axios.post('/iam/users/enable_2fa', { otpAttempt });
  }

  async signOut(): Promise<void> {
    await axios.delete('/iam/account/sign_out');

    this.storage.clear();
  }

  async requestPasswordReset(email: string): Promise<void> {
    await axios.post('iam/account/password', { user: { email } });
  }

  async resetPassword(
    password: string,
    passwordResetToken: string
  ): Promise<void> {
    await axios.put('iam/account/password', {
      user: { password, reset_password_token: passwordResetToken },
    });
  }

  async updatePassword(
    email: string,
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ): Promise<void> {
    await axios.post(`iam/users/update_password`, {
      user: {
        email,
        currentPassword,
        newPassword,
        newPasswordConfirmation,
      },
    });
  }

  async getUserInformation(): Promise<Profile> {
    const response: AxiosResponse<Profile> = await axios.get('/users/me');

    const profile = response.data;

    this.storage.setUserType(profile.type);
    this.storage.setUserEmail(profile.preferredUsername);
    this.storage.setUserRoles(profile.roles);

    return response.data;
  }

  async switchProfile(organizationId: string): Promise<void> {
    await axios.post('/users/switch_organization', { organizationId });
  }

  async getOrganizations(): Promise<OrganizationsResponse> {
    const response = await axios.get('/iam/organizations');

    return response.data;
  }

  async uploadOrganizationLogo(
    organizationId: string,
    logo: File
  ): Promise<OrganizationDetailed> {
    const formData = new FormData();

    formData.append('logo', logo);

    const response = await axios.post(
      `/iam/organizations/${organizationId}/logo`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }

  async importTotalSeats(
    organizationId: string,
    file: File
  ): Promise<AxiosResponse<string>> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(
      `/iam/organizations/${organizationId}/import_children_total_seats`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response;
  }

  async getTotalSeatExport(
    organizationId: string
  ): Promise<AxiosResponse<string>> {
    const response = await axios.get(
      `/iam/organizations/${organizationId}/export_children_total_seats`
    );
    return response;
  }

  async createOrganization(values: AddOrganizationFormValues) {
    const { awardProgramTypes, ...organization } = values;

    const response = await axios.post(`/iam/organizations`, {
      organization,
      awardProgramTypes,
    });

    return response.data;
  }

  async getOrganizationById(id: string): Promise<OrganizationDetailed> {
    const response: AxiosResponse<OrganizationDetailed> = await axios.get(
      `/iam/organizations/${id}`
    );

    return response.data;
  }

  async getOrganizationUsers(id: string): Promise<OrganizationUsersResponse> {
    const response = await axios.get(
      `/iam/organizations/${id}/organization_users`
    );

    return response.data;
  }

  async exportOrganizationUsers(
    organizationId: string,
    includeChildren = false
  ): Promise<AxiosResponse<string>> {
    const response = await axios.get(
      `/iam/organizations/${organizationId}/organization_users/export`,
      {
        params: { includeChildren },
      }
    );

    return response;
  }

  async createOrganizationUser(
    organizationId: string,
    email: string,
    phoneNumber: string,
    roles: Role[],
    isAdmin: boolean | null,
    assignedRegions: OrganizationRegion[] | null
  ) {
    return axios.post(
      `/iam/organizations/${organizationId}/organization_users`,
      {
        organization_user: {
          role_ids: roles.map((r) => r.id),
          is_admin: isAdmin,
          region_ids: assignedRegions?.map((r) => r.id) ?? [],
        },
        user: {
          email: email,
          phone_number: phoneNumber,
        },
      }
    );
  }

  async getOrganizationUserById(
    organizationId: string,
    id: string
  ): Promise<OrganizationUser> {
    const response = await axios.get(
      `/iam/organizations/${organizationId}/organization_users/${id}`
    );

    return response.data;
  }

  async saveOrganizationSettingsById(
    settings: OrganizationDetailed,
    regions: OrganizationRegionEditValue[]
  ): Promise<OrganizationDetailed> {
    const {
      id,
      permissions,
      type,
      isDemo,
      availableRegions,
      assignedRegions,
      awardPrograms,
      ...params
    } = settings;

    const body: any = {
      ...params,
      region_ids: assignedRegions?.map((r) => r.id),
    };

    if (type === 'STATE') {
      body.regions = regions;
    }

    const response: AxiosResponse<OrganizationDetailed> = await axios.put(
      `/iam/organizations/${id}`,
      body
    );

    return response.data;
  }

  async optOutOrganizationFunding(
    organizationId: string,
    fundingType: FundingType
  ): Promise<OrganizationDetailed> {
    const response = await axios.post(
      `/iam/organizations/${organizationId}/funding_opt_out`,
      { fundingType }
    );

    return response.data;
  }

  async saveOrganizationUserById(
    organizationId: string,
    id: string,
    settings: OrganizationUser
  ): Promise<OrganizationUser> {
    const response = await axios.put(
      `/iam/organizations/${organizationId}/organization_users/${id}`,
      {
        organization_user: {
          role_ids: settings.roles?.map((r) => r.id),
          is_admin: settings.isAdmin,
          region_ids: settings.assignedRegions?.map((r) => r.id) ?? [],
        },
        user: {
          first_name: settings.firstName,
          last_name: settings.lastName,
          email: settings.email,
          phone_number: settings.phoneNumber,
        },
      }
    );

    return response.data;
  }

  async reset2fa(organizationId: string, organizationUserId: string) {
    await axios.post(
      `/iam/organizations/${organizationId}/organization_users/${organizationUserId}/reset_2fa`
    );
  }

  async resendInvitationEmail(
    organizationId: string,
    organizationUserId: string
  ) {
    await axios.post(
      `/iam/organizations/${organizationId}/organization_users/${organizationUserId}/resend_invitation_email`
    );
  }

  async acceptUserCreationInvitation(
    invitationToken: string,
    firstName: string,
    lastName: string,
    password: string
  ) {
    return axios.put('iam/account/invitation', {
      user: {
        invitation_token: invitationToken,
        first_name: firstName,
        last_name: lastName,
        password: password,
      },
    });
  }

  async acceptOrganizationInvitation(
    organizationId: string,
    organizationUserId: string
  ) {
    return axios.put(
      `/iam/organizations/${organizationId}/organization_users/${organizationUserId}`,
      {
        organization_user: { status: 'active' },
      }
    );
  }

  async followSignedUrl(signUrl: string) {
    return axios.post(signUrl).then(({ data }: { data: { url: string } }) => {
      window.open(data.url, '_blank')?.focus();
    });
  }

  async getOrganizationRoles(organizationId: string): Promise<RolesResponse> {
    const response = await axios.get(
      `/iam/organizations/${organizationId}/roles`
    );

    return response.data;
  }

  async getOrganizationRole(
    organizationId: string,
    roleId: string
  ): Promise<RoleResponse> {
    const response = await axios.get(
      `/iam/organizations/${organizationId}/roles/${roleId}`
    );

    return response.data;
  }

  async deleteOrganizationRole(
    organizationId: string,
    roleId: number
  ): Promise<void> {
    const response = await axios.delete(
      `/iam/organizations/${organizationId}/roles/${roleId}`
    );

    return response.data;
  }

  async addOrganizationRole(
    organizationId: string,
    roleParams: RoleParams
  ): Promise<RoleResponse> {
    const response = await axios.post(
      `/iam/organizations/${organizationId}/roles/`,
      roleParams
    );

    return response.data;
  }

  async editOrganizationRole(
    organizationId: string,
    role: Role
  ): Promise<RoleResponse> {
    const { id: roleId, ...roleParams } = role;

    const response = await axios.put(
      `/iam/organizations/${organizationId}/roles/${roleId}`,
      roleParams
    );

    return response.data;
  }

  async deactivateOrganizationUser(
    organizationId: string,
    organizationUserId: string
  ): Promise<OrganizationUser> {
    const response = await axios.post(
      `/iam/organizations/${organizationId}/organization_users/${organizationUserId}/deactivate`
    );

    return response.data;
  }

  async activateOrganizationUser(
    organizationId: string,
    organizationUserId: string
  ): Promise<OrganizationUser> {
    const response = await axios.post(
      `/iam/organizations/${organizationId}/organization_users/${organizationUserId}/activate`
    );

    return response.data;
  }

  async deactivateUser(
    organizationId: string,
    organizationUserId: string
  ): Promise<OrganizationUser> {
    const response = await axios.post(
      `/iam/organizations/${organizationId}/organization_users/${organizationUserId}/deactivate_user`
    );

    return response.data;
  }

  async activateUser(
    organizationId: string,
    organizationUserId: string
  ): Promise<OrganizationUser> {
    const response = await axios.post(
      `/iam/organizations/${organizationId}/organization_users/${organizationUserId}/activate_user`
    );

    return response.data;
  }

  async randomizeUserPassword(
    userId: string,
    organizationUserId: string
  ): Promise<RandomizePasswordResponse> {
    const response = await axios.post(
      `/iam/users/${userId}/randomize_password/?organization_user_id=${organizationUserId}`
    );

    return response.data;
  }
}

export default IamService;
