import axios from 'axios';
import { Announcement, NullableAnnouncement } from '../types';

export class AnnouncementService {
  async getAnnouncement(): Promise<NullableAnnouncement> {
    const response = await axios.get('/announcement');
    return response.data;
  }

  async updateAnnouncement(values: Announcement): Promise<Announcement> {
    const { id, markdown, published } = values;
    const response = await axios.put(`/announcements/${id}`, {
      markdown,
      published,
    });
    return response.data;
  }
}
