import { ReactNode, createContext, useContext } from 'react';
import { SpendPlanDocumentService } from './SpendPlanDocumentService';
import { SpendPlanDocumentBudgetItemService } from './SpendPlanDocumentBudgetItemService';
import { SpendPlanDocumentAllocationService } from './SpendPlanDocumentAllocationService';

interface SpendPlanDocumentServices {
  spendPlanDocumentService: SpendPlanDocumentService;
  spendPlanDocumentBudgetItemService: SpendPlanDocumentBudgetItemService;
  spendPlanDocumentAllocationService: SpendPlanDocumentAllocationService;
}

const ServiceContext = createContext<SpendPlanDocumentServices>({
  spendPlanDocumentService: new SpendPlanDocumentService(),
  spendPlanDocumentBudgetItemService: new SpendPlanDocumentBudgetItemService(),
  spendPlanDocumentAllocationService: new SpendPlanDocumentAllocationService(),
});

export function SpendPlanDocumentServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const spendPlanDocumentService = new SpendPlanDocumentService();
  const spendPlanDocumentBudgetItemService =
    new SpendPlanDocumentBudgetItemService();
  const spendPlanDocumentAllocationService =
    new SpendPlanDocumentAllocationService();

  return (
    <ServiceContext.Provider
      value={{
        spendPlanDocumentService,
        spendPlanDocumentBudgetItemService,
        spendPlanDocumentAllocationService,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
}

export function useSpendPlanDocumentServices(): SpendPlanDocumentServices {
  return useContext(ServiceContext);
}
