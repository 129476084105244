import { ReactElement, useState } from 'react';
import RcDropdown from 'rc-dropdown';

interface Props {
  dropdownButtonBuilder: (isOpen: boolean) => ReactElement;
  overlay: ReactElement;
}

export default function DropdownMenu({
  dropdownButtonBuilder,
  overlay,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <RcDropdown
      trigger={['click']}
      overlay={overlay}
      animation="slide-up"
      alignPoint
      onVisibleChange={setIsOpen}
      onOverlayClick={() => setIsOpen(false)}
    >
      {dropdownButtonBuilder(isOpen)}
    </RcDropdown>
  );
}
