import TextInput from '@/components/inputs/TextInput';
import { useCommonServices } from '@/services/CommonServicesProvider';
import { FormEvent, useState } from 'react';
import { useIamService } from '@/features/iam/services/useIamService';
import UnathenticatedBodyView from '@/layouts/UnauthenticatedBodyView';
import { ClockIcon } from '@heroicons/react/24/outline';

export default function Content({
  email,
  invitationExpired,
  invitationToken,
}: {
  email: string;
  invitationExpired: boolean;
  invitationToken: string;
}) {
  const iam = useIamService();
  const { toastService } = useCommonServices();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordsValidationFailed, setPasswordsValidationFailed] =
    useState(false);

  function resetState() {
    setFirstName('');
    setLastName('');
    setPassword('');
    setPasswordConfirmation('');
    setPasswordsValidationFailed(false);
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
      setPasswordsValidationFailed(true);
      return;
    }

    toastService.processing(
      () =>
        iam
          .acceptUserCreationInvitation(
            invitationToken,
            firstName,
            lastName,
            password
          )
          .then((result) => {
            resetState();
            window.location.href = '/login';

            return result;
          }),
      (error) => {
        return error.response.status === 422;
      }
    );
  };

  if (invitationExpired) {
    return (
      <UnathenticatedBodyView
        title="Welcome, we&lsquo;re glad you&lsquo;re here!"
        subTitle="Unfortunately, your invitation has expired."
      >
        <div>
          <ClockIcon className="mx-auto h-40 w-40 text-white" />
        </div>
        <div className="mt-5 text-center text-white">
          Please contact your organization administrator to get a new
          invitation.
        </div>
      </UnathenticatedBodyView>
    );
  }

  return (
    <UnathenticatedBodyView
      title="Invitation"
      subTitle="Enter your information to create an account"
      formConfig={{
        onSubmit,
        mainButtonTitle: 'Create account',
        autoComplete: 'off',
      }}
    >
      <TextInput
        headerName="Email"
        headerClassName="text-white"
        placeholder="Email"
        value={email}
        disabled={true}
      />
      <div className="pt-1">
        <small className="italic">
          Your organization provided this email. To make changes, contact your
          administrator.
        </small>
      </div>

      <TextInput
        headerName="First name"
        headerClassName="text-white mt-5"
        value={firstName}
        placeholder="First name"
        autoComplete="off"
        required
        onChange={(firstName) => {
          setFirstName(firstName.target.value);
        }}
      />

      <TextInput
        headerName="Last name"
        headerClassName="text-white mt-2"
        value={lastName}
        placeholder="Last name"
        autoComplete="off"
        required
        onChange={(lastName) => {
          setLastName(lastName.target.value);
        }}
      />

      <TextInput
        headerName="Create password"
        headerClassName="text-white mt-5"
        value={password}
        type="password"
        placeholder="Password"
        required
        onChange={(password) => {
          setPassword(password.target.value);
          setPasswordsValidationFailed(false);
        }}
        autoComplete="new-password"
      />

      <TextInput
        headerName="Confirm password"
        headerClassName="text-white mt-2"
        value={passwordConfirmation}
        type="password"
        placeholder="Password confirmation"
        required
        onChange={(passwordConfirmation) => {
          setPasswordConfirmation(passwordConfirmation.target.value);
          setPasswordsValidationFailed(false);
        }}
        autoComplete="off"
        isValid={!passwordsValidationFailed}
        name={passwordsValidationFailed ? 'Passwords must match' : undefined}
      />
    </UnathenticatedBodyView>
  );
}
