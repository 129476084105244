import { ReactNode } from 'react';
import Loader from '@/components/Loader';
import { useQuery } from 'react-query';
import { useCommonServices } from './services/CommonServicesProvider';
import { useIamService } from './features/iam/services/useIamService';
import { useConfigService } from './features/config/services/useConfigService';
import { ConfigProvider } from './features/config/components/ConfigProvider';

export default function Init({ children }: { children: ReactNode }) {
  const iam = useIamService();
  const configService = useConfigService();
  const { logService } = useCommonServices();

  const { isLoading, data } = useQuery(
    'Initialize app dependencies',
    () => Promise.all([iam.getUserInformation(), configService.getConfig()]),
    {
      retry: 10,
      retryDelay: 1000,
      suspense: false,
      onError: (error: Error) => {
        logService.error(`Error fetching data:${error}`);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  if (isLoading) {
    return (
      <div className="h-full w-full">
        <Loader />
      </div>
    );
  }

  if (data == null) {
    throw Error('Data cannot be null for initial dependencies');
  }

  return <ConfigProvider config={data[1]}>{children}</ConfigProvider>;
}
