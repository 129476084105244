import { Navigate, useLocation } from 'react-router-dom';

/// We need this because when Amplify redirects to index.html and there is no trailing slash, Amplify removes search params
/// https://github.com/aws-amplify/amplify-hosting/issues/97
export const TrailingSlashAdder = ({ ...rest }) => {
  const location = useLocation();

  if (!location.pathname.trim().endsWith('/')) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: `${location.pathname}/`,
          search: location.search,
        }}
      />
    );
  } else return null;
};
