import { ReactNode, createContext, useContext } from 'react';
import { AwardService } from '.';

const ServiceContext = createContext(new AwardService());

export function AwardServiceProvider({ children }: { children: ReactNode }) {
  const awardService = new AwardService();

  return (
    <ServiceContext.Provider value={awardService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useAwardService(): AwardService {
  return useContext(ServiceContext);
}
