import { ReactNode } from 'react';
import Button from './Button';

interface Option {
  node?: ReactNode;
  text?: string;
  description?: string;
  onClick?: () => void;
}

interface Props {
  title: string;
  options: Option[];
  disabled?: boolean;
}

export default function DropdownButton({
  title,
  options,
  disabled = false,
}: Props) {
  return (
    <div className="dropdown-bottom dropdown-end dropdown !border-0 !bg-transparent">
      <label tabIndex={0} style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
        <Button disabled={disabled}>
          {title}{' '}
          <span
            className="arrow-drop-down"
            style={{ backgroundColor: 'white' }}
          />
        </Button>
      </label>
      <ul
        tabIndex={0}
        className="dropdown-content menu rounded-box z-[1] bg-white p-2 shadow"
        style={{ margin: 0, width: '20rem' }}
      >
        {options.map((option, index) => (
          <div key={`${option.text} + ${option.description}`}>
            <li>
              {option.node != null ? (
                option.node
              ) : (
                <a onClick={option.onClick}>
                  <div className="flex flex-col">
                    <p className="text-base-m font-medium">{option.text}</p>
                    {option.description && (
                      <p className="text-base-sm font-normal">
                        {option.description}
                      </p>
                    )}
                  </div>
                </a>
              )}
            </li>
            {index !== options.length - 1 && <div className="divider m-0" />}
          </div>
        ))}
      </ul>
    </div>
  );
}
