import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const InvoicesTable = lazy(
  () => import('../pages/table-page/InvoicesTablePage')
);

const InvoiceDetails = lazy(
  () => import('../pages/details-page/InvoiceDetailsPage')
);

const InvoicesRoutes = () => (
  <Routes>
    <Route path="" element={<InvoicesTable />} />
    <Route path="/:invoiceId" element={<InvoiceDetails />} />
  </Routes>
);

export default InvoicesRoutes;
