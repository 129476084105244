import axios from 'axios';
import { GlobalConfig } from '../types';

export default class ConfigService {
  async getConfig(): Promise<GlobalConfig> {
    const response = await axios.get('/config');

    return response.data;
  }
}
