import TextInput from '@/components/inputs/TextInput';
import { LoginValues } from '../Login';
import { FormEvent } from 'react';
import UnathenticatedBodyView from '@/layouts/UnauthenticatedBodyView';
import Button from '@/components/Button';

interface Props {
  values: LoginValues;
  setValues: (values: LoginValues) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const LoginCredentials = ({ values, setValues, onSubmit }: Props) => {
  return (
    <UnathenticatedBodyView
      title="Welcome, we&lsquo;re glad you&lsquo;re here!"
      subTitle="Enter your email and password to sign in"
      formConfig={{ onSubmit, mainButtonTitle: 'Sign in' }}
    >
      <TextInput
        headerName="Email"
        headerClassName="text-white"
        name="email"
        type="text"
        placeholder="Email"
        autoComplete="off"
        onChange={(email) => {
          setValues({ ...values, email: email.target.value });
        }}
        required
      />

      <TextInput
        headerName="Password"
        headerClassName="text-white mt-6"
        name="password"
        type="password"
        placeholder="Password"
        autoComplete="off"
        onChange={(password) => {
          setValues({ ...values, password: password.target.value });
        }}
        required
      />

      <div className="mt-2 flex justify-end">
        <Button
          type="text"
          textColor="text-white"
          onClick={() => {
            window.location.href = '/forgot-password/';
          }}
        >
          Forgot password?
        </Button>
      </div>
    </UnathenticatedBodyView>
  );
};

export default LoginCredentials;
