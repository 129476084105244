import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const OrganizationRolesTable = lazy(
  () => import('../pages/organization-roles-table/OrganizationRolesTable')
);
const OrganizationRoleSettings = lazy(
  () =>
    import('../pages/organization-role-settings/OrganizationRoleSettingsPage')
);

const OrganizationRolesRoutes = () => (
  <Routes>
    <Route path="" element={<OrganizationRolesTable />} />
    <Route path="/:roleId" element={<OrganizationRoleSettings />} />
  </Routes>
);

export default OrganizationRolesRoutes;
