import { Link } from 'react-router-dom';
import { Row } from 'react-table';
import { TableOnClickType } from './TableBody';

interface Props<T extends object> {
  row: Row<T>;
  cell: any;
  onClick?: TableOnClickType<T>;
}

export default function RowBuilder<T extends object>({
  row,
  cell,
  onClick = () => null,
}: Props<T>) {
  const baseStyles = 'cursor-pointer w-full h-full block px-4 py-3';

  const onClickAction = onClick(row.original);

  if (onClickAction === 'NOTHING') {
    return (
      <div {...cell.getCellProps()} className="px-4 py-2.5">
        {cell.render('Cell')}
      </div>
    );
    // eslint-disable-next-line @typescript-eslint/dot-notation
  } else if (onClickAction === null && row.original['id']) {
    return (
      // eslint-disable-next-line @typescript-eslint/dot-notation
      <Link className={baseStyles} to={row.original['id'].toString()}>
        {cell.render('Cell')}
      </Link>
    );
  } else if (typeof onClickAction === 'function') {
    return (
      <div
        onClick={() => onClickAction()}
        className={`${baseStyles} px-4 py-2.5`}
      >
        {cell.render('Cell')}
      </div>
    );
  }

  return null;
}
