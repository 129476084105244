import AwardOverviewRoutes from '@/features/awards/routes/AwardOverviewRoutes';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const CpgTemplates = lazy(() => import('../pages/cpg-templates'));

const CpgTemplate = lazy(() => import('../pages/cpg-template-flow'));

const CpgTemplatesRoutes = () => (
  <Routes>
    <Route path="" element={<CpgTemplates />} />
    <Route path="/award-overview/*" element={<AwardOverviewRoutes />} />
    <Route path="/:cpgTemplateId" element={<CpgTemplate />} />
  </Routes>
);

export default CpgTemplatesRoutes;
