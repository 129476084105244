import columns from './columns';
import { OrganizationUser } from '@/features/iam/types';
import useCommonTable from '@/components/table/Table';

const Index = ({
  organizationUsers,
}: {
  organizationUsers: OrganizationUser[];
}) => {
  const { table, pagination } = useCommonTable({
    data: organizationUsers,
    columns,
  });

  return (
    <div className="mb-6">
      <div className="table-add-radius">{table}</div>
      <div>{pagination}</div>
    </div>
  );
};

export default Index;
