import axios from 'axios';
import {
  PlannedActivitiesResponse,
  PlannedActivity,
  PlannedActivityFormData,
} from '@/features/planned-activities/types';

export abstract class PlannedActivityService {
  basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  async getPlannedActivities(
    templateId: string
  ): Promise<PlannedActivitiesResponse[]> {
    const response = await axios.get(
      `${this.basePath}/${templateId}/planned_activities`
    );

    return response.data;
  }

  async editPlannedActivity(
    templateId: string,
    plannedActivityId: string,
    editedPlannedActivity: PlannedActivityFormData
  ): Promise<PlannedActivity> {
    const {
      connectedContainerPairs,
      templateConnectedContainerPairs,
      createdInTemplate,
      templateOutputs,
      isNewlyCreated,
      containerId,
      comments,
      label,
      sublabel,
      updatedAt,
      ...editPayload
    } = editedPlannedActivity;

    // eslint-disable-next-line @typescript-eslint/dot-notation
    delete editPayload['id'];

    const response = await axios.put(
      `${this.basePath}/${templateId}/planned_activities/${plannedActivityId}`,
      {
        planned_activity: {
          ...editPayload,
          connectedContainerIds:
            editedPlannedActivity.connectedContainerPairs?.map(
              (containerPair) => containerPair.child.id
            ),
        },
      }
    );

    return response.data;
  }

  async addPlannedActivity(
    templateId: string,
    parentActivityId: string | number,
    newPlannedActivity: PlannedActivityFormData
  ): Promise<PlannedActivity> {
    const { connectedContainerPairs, ...addPayload } = newPlannedActivity;

    const response = await axios.post(
      `${this.basePath}/${templateId}/planned_activities`,
      {
        planned_activity: {
          ...addPayload,
          parentId: parentActivityId,
          connectedContainerIds:
            newPlannedActivity.connectedContainerPairs?.map(
              (containerPair) => containerPair.child.id
            ),
        },
      }
    );

    return response.data;
  }

  async removePlannedActivity(
    templateId: string,
    plannedActivityId: string
  ): Promise<void> {
    const response = await axios.delete(
      `${this.basePath}/${templateId}/planned_activities/${plannedActivityId}`
    );

    return response.data;
  }

  async addPlannedActivityComment(
    templateOrDocumentId: string,
    plannedActivityId: string,
    content = ''
  ): Promise<PlannedActivity> {
    const response = await axios.post(
      `${this.basePath}/${templateOrDocumentId}/planned_activities/${plannedActivityId}/comment`,
      { comment: { content } }
    );

    return response.data;
  }
}
