import { ReactNode, createContext, useContext } from 'react';
import { WorkPlanDocumentService } from './WorkPlanDocumentService';
import { WorkPlanDocumentPlannedActivityService } from './WorkPlanDocumentPlannedActivityService';
import { WorkPlanDocumentFophObjectiveService } from './WorkPlanDocumentFophObjectiveService';
import { WorkPlanFormInputService } from './WorkPlanFormInputService';

interface WorkPlanDocumentServices {
  workPlanDocumentService: WorkPlanDocumentService;
  workPlanDocumentPlannedActivityService: WorkPlanDocumentPlannedActivityService;
  workPlanDocumentFophObjectiveService: WorkPlanDocumentFophObjectiveService;
  workPlanFormInputService: WorkPlanFormInputService;
}

const ServiceContext = createContext<WorkPlanDocumentServices>({
  workPlanDocumentService: new WorkPlanDocumentService(),
  workPlanDocumentPlannedActivityService:
    new WorkPlanDocumentPlannedActivityService(),
  workPlanDocumentFophObjectiveService:
    new WorkPlanDocumentFophObjectiveService(),
  workPlanFormInputService: new WorkPlanFormInputService(),
});

export function WorkPlanDocumentServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const workPlanDocumentService = new WorkPlanDocumentService();
  const workPlanDocumentPlannedActivityService =
    new WorkPlanDocumentPlannedActivityService();
  const workPlanDocumentFophObjectiveService =
    new WorkPlanDocumentFophObjectiveService();
  const workPlanFormInputService = new WorkPlanFormInputService();

  return (
    <ServiceContext.Provider
      value={{
        workPlanDocumentService,
        workPlanDocumentPlannedActivityService,
        workPlanDocumentFophObjectiveService,
        workPlanFormInputService,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
}

export function useWorkPlanDocumentServices(): WorkPlanDocumentServices {
  return useContext(ServiceContext);
}
