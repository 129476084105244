import { ReactNode, createContext, useContext } from 'react';
import { CommentService } from '.';

const ServiceContext = createContext<CommentService>(new CommentService());

export function CommentServiceProvider({ children }: { children: ReactNode }) {
  const commentService = new CommentService();

  return (
    <ServiceContext.Provider value={commentService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useCommentService(): CommentService {
  return useContext(ServiceContext);
}
