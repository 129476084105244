import TextInput from '@/components/inputs/TextInput';
import { LoginValues } from '../Login';
import { FormEvent } from 'react';
import UnathenticatedBodyView from '@/layouts/UnauthenticatedBodyView';

interface Props {
  values: LoginValues;
  setValues: (values: LoginValues) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const OtpVerification = ({ values, setValues, onSubmit }: Props) => {
  return (
    <UnathenticatedBodyView
      title="2-Step Verification"
      subTitle="Enter your 6-digit code from your Authenticator app to finish signing in"
      formConfig={{ onSubmit, mainButtonTitle: 'Sign in' }}
    >
      <TextInput
        headerName="Verification code"
        headerClassName="text-white"
        name="otpAttempt"
        type="text"
        placeholder="Verification code"
        autoComplete="off"
        onChange={(otpAttempt) => {
          setValues({ ...values, otpAttempt: otpAttempt.target.value });
        }}
        required
      />
    </UnathenticatedBodyView>
  );
};

export default OtpVerification;
