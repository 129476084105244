import Button from '@/components/Button';
import UnathenticatedBodyView from '@/layouts/UnauthenticatedBodyView';

export default function SetupAuthenticator({
  setCurrentStep,
  qrCode,
}: {
  setCurrentStep: (step: number) => void;
  qrCode: string;
}) {
  return (
    <UnathenticatedBodyView
      title="Setup Authenticator"
      subTitle="Scan the QR code with the Authenticator app"
    >
      <div className="mb-3 flex flex-col items-center justify-center">
        <div className="white-box rounded-sm border border-light-grey bg-white p-4">
          <img src={qrCode} alt="QR code" />
        </div>
      </div>
      <div className="my-4">
        <Button type="secondary" onClick={() => setCurrentStep(2)}>
          Continue
        </Button>
      </div>
    </UnathenticatedBodyView>
  );
}
