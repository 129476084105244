import { Outlet } from 'react-router-dom';

import NavigationLayout from '@/layouts/NavigatorView';
import usePdfView from '@/hooks/usePdfView';

export default function NavigationLayoutRouter() {
  const pdfView = usePdfView();

  return (
    <div className={pdfView ? 'overflow-visible' : ''}>
      <NavigationLayout withoutNavigation={pdfView}>
        <Outlet />
      </NavigationLayout>
    </div>
  );
}
