/* eslint-disable react/jsx-key */

import {
  Column,
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
} from 'react-table';
import Checkbox from '../inputs/Checkbox';
import RowBuilder from './RowBuilder';
import Pagination, { PaginationProps } from './Pagination';
import { ReactNode } from 'react';

export interface SelectionProps<T extends object> {
  selectedRows: T[];
  onSelect: (selectedRows: T[]) => void;
}

export type TableColumn<T extends object> = Column<T> & {
  withFullWidth?: boolean;
  visible?: boolean;
};

export type TableOnClickType<T> = (value: T) => null | (() => void) | 'NOTHING';

interface Props<T extends object> {
  data: T[];
  page: Row<T>[];
  headerGroups: HeaderGroup<T>[];
  prepareRow: (row: Row<T>) => void;
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<T> | undefined
  ) => TableBodyProps;
  filterHidden?: (column: any) => boolean;
  onClick?: TableOnClickType<T>;
  highlight?: (value: T) => boolean;
  selection?: SelectionProps<T>;
  paginationProps: PaginationProps;
}

export default function getTableBody<T extends object>({
  data,
  page,
  headerGroups,
  prepareRow,
  getTableBodyProps,
  filterHidden = () => true,
  onClick = () => null,
  highlight = () => false,
  selection,
  paginationProps,
}: Props<T>) {
  // TODO: Handle backend table selection
  const selectable = selection != null;
  const selectedData = selection?.selectedRows ?? [];
  const setSelectedData = selection?.onSelect ?? (() => {});
  const isSelectAll = data.every((d) => selectedData.includes(d));

  function onSelect(entry: T) {
    const newData = selectedData.includes(entry)
      ? selectedData.filter((d) => d !== entry)
      : [...selectedData, entry];

    setSelectedData(newData);
  }

  function onSelectAll() {
    const newData = isSelectAll ? [] : [...data];

    setSelectedData(newData);
  }

  const headerClass =
    'text-xs bg-grey-50 bg-pale-grey py-3 pl-4 text-left font-medium tracking-wider text-grey-30';
  const rowClass = 'border-y border-solid border-light-grey';
  const cellClass = 'text-xs font-semibold text-grey-black';

  return {
    table: (
      <table className="grant-table min-w-full table-auto rounded-lg bg-white">
        <thead className="table-head">
          {headerGroups.map((hG: HeaderGroup<T>) => (
            <tr
              className="table-head-row sticky top-0 whitespace-nowrap"
              {...hG.getHeaderGroupProps()}
            >
              <>
                {selectable && (
                  <th scope="col" className={headerClass} title="Select">
                    <Checkbox
                      id={`Select all checkbox`}
                      isChecked={isSelectAll}
                      onAnswer={() => onSelectAll()}
                    />
                  </th>
                )}
                {hG.headers
                  .filter(
                    (hG) =>
                      filterHidden(hG.Header) &&
                      (hG as TableColumn<T>).visible !== false
                  )
                  .map((col: HeaderGroup<T>, index) => (
                    <th
                      scope="col"
                      className={`${headerClass} ${index === hG.headers.length - 1 ? 'pr-4' : ''} `}
                      {...col.getHeaderProps(col.getSortByToggleProps())}
                      title={`${col.canSort ? 'Sort by' : ''} ${col.Header}`}
                    >
                      {col.render('Header')}
                      {col.canSort && (
                        <span>
                          {col.isSorted ? (
                            col.isSortedDesc ? (
                              <span className="arrow-drop-up" />
                            ) : (
                              <span className="arrow-drop-down" />
                            )
                          ) : (
                            <span className="arrow-right" />
                          )}
                        </span>
                      )}
                    </th>
                  ))}
              </>
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="table-body whitespace-nowrap rounded-lg text-grey-black"
        >
          {page.map((row: Row<T>, index) => {
            prepareRow(row);

            const isSelected = selectedData.includes(row.original);
            const isHighlighted = highlight(row.original);
            const backgroundColor = isSelected
              ? 'bg-purple-40'
              : isHighlighted
                ? 'bg-orange-50'
                : 'bg-white';

            const hover = isHighlighted
              ? 'hover:bg-orange'
              : 'hover:bg-purple-50';

            return (
              <tr
                className={`${rowClass} ${hover} ${backgroundColor}`}
                {...row.getRowProps()}
              >
                <>
                  {selectable && (
                    <td className={`${cellClass} px-4 py-2.5`}>
                      <Checkbox
                        id={`${index}`}
                        isChecked={selectedData.includes(row.original)}
                        onAnswer={() => onSelect(row.original)}
                      />
                    </td>
                  )}
                  {row.cells
                    .filter(
                      (hG) =>
                        filterHidden(hG.column.Header) &&
                        (hG.column as TableColumn<T>).visible !== false
                    )
                    .map((cell: any) => (
                      <td
                        {...cell.getCellProps()}
                        className={`${cellClass} ${cell?.column?.withFullWidth === true ? 'w-full' : ''}`}
                      >
                        <RowBuilder cell={cell} row={row} onClick={onClick} />
                      </td>
                    ))}
                </>
              </tr>
            );
          })}
        </tbody>
      </table>
    ),
    pagination: <Pagination {...paginationProps} />,
    functionalHeaderGetter: (
      customFilters?: ReactNode,
      customActions?: ReactNode
    ) => (
      <>
        <div className="relative flex w-full justify-between gap-4 self-center rounded-lg bg-white p-3.5">
          <div className="flex flex-wrap gap-4">
            {headerGroups.map((hG: HeaderGroup<T>, index: number) => (
              <div key={index}>
                <div
                  className="flex flex-wrap gap-4"
                  {...hG.getHeaderGroupProps()}
                >
                  {hG.headers.map((col: HeaderGroup<T>, index: number) =>
                    col.disableFilters ? null : (
                      <div
                        key={index}
                        className="text-xs divide-y-1 text-left font-medium tracking-wider"
                      >
                        {col.canFilter ? (
                          <div className="rounded-lg bg-white">
                            {col.render('Filter')}
                          </div>
                        ) : null}
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
            {customFilters}
          </div>
        </div>
        {customActions}
      </>
    ),
  };
}
