import axios from 'axios';
import { Notification, NotificationsData } from '../types';

export class NotificationsService {
  async getUserNotifications(
    unread: boolean,
    page: number
  ): Promise<NotificationsData> {
    const response = await axios.get('/notifications', {
      params: { page, unread },
    });

    return response.data;
  }

  async changeNotificationIsReadStatus(
    notificationId: number,
    isRead: boolean
  ): Promise<Notification> {
    const response = await axios.put(`/notifications/${notificationId}`, {
      notification: { isRead },
    });
    return response.data;
  }

  async bulkUpdateIsReadStatus(isRead: boolean) {
    await axios.post('/notifications/bulk_update_read_status', {
      isRead,
    });
  }
}
