import { ReactNode } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

type Type = 'error';

interface Props {
  type?: Type;
  children?: ReactNode;
}

const typeClasses: Map<Type, string> = new Map([
  ['error', 'alert alert-error '],
]);

const textColorClasses: Map<Type, string> = new Map([['error', 'text-white']]);
const typeIcons: Map<Type, ReactNode> = new Map([
  ['error', <XCircleIcon className="h-6 w-6" key="error" />],
]);

export default function AlertBox({ type = 'error', children }: Props) {
  const tColor = textColorClasses.get(type);

  const finalClass = `${tColor} ${typeClasses.get(type)}`;

  return (
    <div role="alert" className={finalClass}>
      {typeIcons.get(type)}
      {children}
    </div>
  );
}
