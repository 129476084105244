import { PaginatedDataRequest } from '@/components/table/BackendTable';

export function getQueryParamsFromPaginationRequest(
  request: PaginatedDataRequest,
  { sortByMapper }: { sortByMapper?: Map<string, string> } = {}
) {
  let result = `page=${request.page}`;

  if (request.sortBy) {
    const sortBy = sortByMapper?.get(request.sortBy) ?? request.sortBy;

    result += `&sort_by=${sortBy}`;
  }

  if (request.sortDirection) {
    result += `&sort_direction=${request.sortDirection}`;
  }

  if (request.filters) {
    result += request.filters
      .map((f) => {
        const filterValue = f.value;
        const isArray = Array.isArray(filterValue);

        if (isArray && filterValue.length <= 0) {
          return '';
        }

        const value = isArray ? filterValue.join(',') : filterValue;

        return `&${f.id}=${value}`;
      })
      .join('');
  }

  return result;
}
