import axios from 'axios';
import {
  AwardDefinition,
  AwardDefinitionAwardContainerParams,
  AwardDefinitionContainerParams,
  AwardDefinitionDetailed,
} from '../types';
import { BudgetCategory } from '@/features/budget-categories/types';

export class AwardDefinitionService {
  async getAwardDefinitions(): Promise<AwardDefinition[]> {
    const response = await axios.get(`/award_definitions`);

    return response.data;
  }

  async getAwardDefinition(
    awardDefinitionId: string
  ): Promise<AwardDefinitionDetailed> {
    const response = await axios.get(`/award_definitions/${awardDefinitionId}`);

    return response.data;
  }

  async getBudgetCategories(
    awardDefinitionId: string
  ): Promise<BudgetCategory[]> {
    const response = await axios.get(
      `/award_definitions/${awardDefinitionId}/budget_categories`
    );

    return response.data;
  }

  async createBudgetCategory(
    awardDefinitionId: number,
    name: string,
    isGroupedAsOther: boolean
  ): Promise<BudgetCategory> {
    const response = await axios.post(
      `/award_definitions/${awardDefinitionId}/budget_categories`,
      {
        budget_category: {
          name,
          isGroupedAsOther,
        },
      }
    );

    return response.data;
  }

  async editBudgetCategory(
    awardDefinitionId: number,
    budgetCategoryId: string,
    name: string,
    isGroupedAsOther: boolean
  ): Promise<BudgetCategory> {
    const response = await axios.put(
      `/award_definitions/${awardDefinitionId}/budget_categories/${budgetCategoryId}`,
      {
        budget_category: {
          name,
          isGroupedAsOther,
        },
      }
    );

    return response.data;
  }

  async editContainer(
    awardDefinitionId: number,
    containerId: string | number,
    containerParams: AwardDefinitionContainerParams,
    awardContainerParams: AwardDefinitionAwardContainerParams
  ): Promise<AwardDefinitionDetailed> {
    const response = await axios.put(
      `/award_definitions/${awardDefinitionId}/container/${containerId}`,
      { container: containerParams, award_container: awardContainerParams }
    );

    return response.data;
  }

  async addContainer(
    awardDefinitionId: number,
    containerParams: AwardDefinitionContainerParams,
    awardContainerParams: AwardDefinitionAwardContainerParams
  ): Promise<AwardDefinitionDetailed> {
    const response = await axios.post(
      `/award_definitions/${awardDefinitionId}/container/`,
      { container: containerParams, award_container: awardContainerParams }
    );

    return response.data;
  }
}
