import { AwardType } from '@/features/awards/types';

export interface Profile {
  organizations: Organization[];
  type: UserType;
  id: string;
  givenName: string;
  familyName: string;
  preferredUsername: string;
  activeOrganizationId: string;
  roles?: Role[];
  hasUnreadNotifications: boolean;
}

export interface Organization {
  id: string;
  name: string;
  type: OrganizationType;
  isDemo: boolean;
  availableRegions: OrganizationRegion[];
  assignedRegions: OrganizationRegion[];
  hasLab?: boolean;
  eligibleForCriFunding?: boolean;
  eligibleForLabFunding?: boolean;
  hasActiveCriFunding?: boolean;
  hasActiveLabFunding?: boolean;
  logoUrl?: string;
  helpLink?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  stateContactEmail?: string;
  fiscalAgentName?: string;
  usedSeats: number;
  allocatedSeats: number;
  paymentMailingAddress: {
    addressLineFirst?: string;
    addressLineSecond?: string;
    city?: string;
    postalCode?: string;
  };
}

export interface OrganizationUsersResponse {
  data: OrganizationUser[];
  permissions: {
    create: {
      roles: Role[];
      regions: OrganizationRegion[];
      canSetAdmin: boolean;
    };
  };
}

export interface OrganizationUser {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  roles?: Role[];
  status: OrganizationUserStatus;
  userStatus: OrganizationUserStatus;
  assignedRegions?: OrganizationRegion[] | null;
  availableRegions?: OrganizationRegion[];
  lastSignInDate?: string;
  isAdmin: boolean;
  permissions: {
    edit: {
      roles: Role[];
      canSetAdmin: boolean;
      canResendInvitation: boolean;
      canChangeActivationState: boolean;
      canChangeUserActivationState: boolean;
    };
  };
}

export interface Role {
  id: number;
  name: string;
  assignedGrants: AwardType[];
  assignedPlans: PlanType[];
  templatePermissions?: PermissionType[];
  documentPermissions: PermissionType[];
}

export interface RolesResponse {
  data: Role[];
  permissions: {
    create?: {
      possibleGrants: AwardType[];
      possiblePlans: PlanType[];
    };
  };
}

export interface RoleResponse {
  role: Role;
  permissions: {
    edit?: {
      possibleGrants: AwardType[];
      possiblePlans: PlanType[];
    };
    delete: boolean;
  };
}

export type RoleParams = Omit<Role, 'id'>;

export const planTypeList = ['CPG', 'WORK_PLAN', 'SPEND_PLAN'] as const;

export type PlanType = (typeof planTypeList)[number];

export function sortedPlanTypes(plans: PlanType[]) {
  const orderedPlans: PlanType[] = ['CPG', 'WORK_PLAN', 'SPEND_PLAN'];
  const result: PlanType[] = [];

  for (const orderedPlan of orderedPlans) {
    if (plans.includes(orderedPlan)) {
      result.push(orderedPlan);
    }
  }

  return [...result, ...plans.filter((p) => !orderedPlans.includes(p))];
}

export const permissionTypeList = ['EDIT', 'APPROVE'] as const;

export type PermissionType = (typeof permissionTypeList)[number];

export type OrganizationUserStatus = 'pending' | 'active' | 'inactive';

export interface OrganizationsResponse {
  data: OrganizationDetailed[];
  permissions: {
    create: {
      organizationTypes: OrganizationType[];
      awardTypes: AwardType[];
    };
  };
}

export const organizationPopulationSizeList = [
  'less_than_200k',
  'between_200k_and_700k',
  'more_than_700k',
] as const;

export type OrganizationPopulationSize =
  (typeof organizationPopulationSizeList)[number];

export interface OrganizationRegion {
  id: number;
  name: string;
}

export interface OrganizationRegionEditValue {
  id?: number;
  name: string;
}

export interface OrganizationDetailed extends Organization {
  populationSize?: OrganizationPopulationSize;
  totalSeats: number;
  availableSeats: number;
  stateTotalSeats: number;
  stateTotalAvailableSeats: number;
  stateTotalUsedSeats: number | null;
  awardPrograms: AwardType[];
  permissions: {
    edit: boolean;
  };
  organizationUser?: {
    id: string;
    status: OrganizationUserStatus;
  };
}

export type UserType = 'STATE' | 'LOCAL' | 'GOD';

export type OrganizationType = 'STATE' | 'LOCAL' | 'GOD';

export interface Setup2faResponse {
  qrCode: string;
  backupCodes: string[];
}

export interface RandomizePasswordResponse {
  password: string;
  appDomain: string;
}

export interface CheckInvitationStatusResponse {
  email: string;
}
