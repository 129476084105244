import { ReactNode, createContext, useContext } from 'react';
import { ExpenditureReportService } from '.';

const ServiceContext = createContext<ExpenditureReportService>(
  new ExpenditureReportService()
);

export function ExpenditureReportServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const expenditureReportService = new ExpenditureReportService();

  return (
    <ServiceContext.Provider value={expenditureReportService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useExpenditureReportService(): ExpenditureReportService {
  return useContext(ServiceContext);
}
