import { Outlet } from 'react-router-dom';

const SettingsLayoutRoute = () => {
  return (
    <div className="mx-6 mt-7">
      <h1 className="text-2xl mb-3 font-bold text-grey-black">
        Organization settings
      </h1>
      <Outlet />
    </div>
  );
};

export default SettingsLayoutRoute;
