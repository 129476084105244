import {
  PropsWithChildren,
  useContext,
  useEffect,
  createContext,
  useCallback,
  useState,
} from 'react';
import { Consumer, createConsumer, Subscription } from '@rails/actioncable';
import { useCommonServices } from './CommonServicesProvider';

interface CableContextType {
  cable: Consumer;
  unsubscribeAll: () => void;
}

export const CableContext = createContext({} as CableContextType);

const CABLE_URL = import.meta.env.VITE_CABLE_URL;

const CableProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { storageService, logService } = useCommonServices();

  const [token, setToken] = useState(storageService.getToken());
  const [cable, setCable] = useState<Consumer>(
    createConsumer(`${CABLE_URL}?token=${token}`)
  );

  useEffect(() => {
    const onTokenChange = (newToken: string) => {
      if (newToken !== token) {
        setToken(newToken);
      }
    };

    storageService.addTokenListener(onTokenChange);

    return () => {
      storageService.removeTokenListener(onTokenChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cableUrl = `${CABLE_URL}?token=${token}`;

    if (cable?.url == cableUrl) {
      return;
    }

    setCable(createConsumer(cableUrl));

    return () => {
      try {
        cable.disconnect();
      } catch (error: unknown) {
        logService.error('Error disconnecting from cable');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const unsubscribeAll = useCallback(() => {
    cable.subscriptions.subscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }, [cable]);

  return (
    <CableContext.Provider value={{ cable, unsubscribeAll }}>
      {children}
    </CableContext.Provider>
  );
};

interface CableSubscriptionCallbacks<T> {
  connected?: () => void;
  disconnected?: () => void;
  received: (data: T) => void;
}

export const useCableSubscription = <T,>(
  channel: string,
  params: { [key: string]: any },
  callbacks: CableSubscriptionCallbacks<T>
) => {
  const { cable } = useContext(CableContext);
  const url = cable.url;

  useEffect(() => {
    let subscription: Subscription;

    const timeout = setTimeout(() => {
      subscription = cable.subscriptions.create(
        { channel, ...params },
        callbacks
      );
    }, 100); // Allow unsubscribing previous subscription

    return () => {
      clearTimeout(timeout);
      subscription?.unsubscribe();
    };
  }, [cable, url, channel, params, callbacks]);
};

export default CableProvider;
