import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const ExpenditureReportPage = lazy(
  () => import('../pages/ExpenditureReportPage')
);

export default function ExpenditureReportRoutes() {
  return (
    <Routes>
      <Route path="" element={<ExpenditureReportPage />} />
    </Routes>
  );
}
