import { ReactNode, createContext, useContext } from 'react';
import { PersonnelReportService } from '.';

const ServiceContext = createContext<PersonnelReportService>(
  new PersonnelReportService()
);

export function PersonnelReportServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const personnelReportService = new PersonnelReportService();

  return (
    <ServiceContext.Provider value={personnelReportService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function usePersonnelReportService(): PersonnelReportService {
  return useContext(ServiceContext);
}
