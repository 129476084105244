import axios from 'axios';

export abstract class AllocationService {
  basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  async editAllocation(
    templateOrDocumentId: string,
    nominalAllocation: number
  ): Promise<number> {
    const response = await axios.put(
      `${this.basePath}/${templateOrDocumentId}/allocations/`,
      {
        nominalAllocation,
      }
    );

    return response.data;
  }
}
