import { useState } from 'react';

export interface ModalController {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

const useModalController = (
  args: {
    isOpenDefault: boolean;
  } = { isOpenDefault: false }
): ModalController => {
  const [isOpen, setOpen] = useState<boolean>(args.isOpenDefault);

  return {
    isOpen,
    open: () => setOpen(true),
    close: () => setOpen(false),
    toggle: () => setOpen((val) => !val),
  };
};

export default useModalController;
