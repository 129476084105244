import axios from 'axios';
import { PersonnelReport } from '../types';

export class PersonnelReportService {
  async getPersonnelReport(
    spendPlanTemplateIds: number[]
  ): Promise<PersonnelReport> {
    const response = await axios.get(
      `/personnel_reports?spend_plan_template_ids=${spendPlanTemplateIds.join(',')}`
    );

    return response.data;
  }
}
