import axios from 'axios';
import { extractFileName } from '@/features/file-download/utils/file-download-utils';
import {
  ReportListEntry,
  CpgReport,
  ReportTemplate,
  ReportTemplateDetailed,
  ReportTemplateParams,
  ReportTemplateSheet,
  ReportTemplateSheetParams,
  ReportTemplateHeader,
  ReportTemplateHeaderDetailed,
  ReportTemplateHeaderParams,
  ReportTemplateSheetDetailed,
  ReportTemplateHeaderDeleteResponse,
  ReportCreationInfo,
  ReportCreationPayload,
  ReportDetailed,
  ReportDetailedSheet,
  ReportsResponse,
} from '../types';

export class ReportService {
  async getReports(): Promise<ReportsResponse> {
    const response = await axios.get('/reports');

    return response.data;
  }

  async getReportTemplates(): Promise<ReportTemplate[]> {
    const response = await axios.get('/report_templates');

    return response.data;
  }

  async getReportTemplate(
    reportTemplateId: string
  ): Promise<ReportTemplateDetailed> {
    const response = await axios.get(`/report_templates/${reportTemplateId}`);

    return response.data;
  }

  async createReportTemplate(
    reportTemplateParams: ReportTemplateParams
  ): Promise<ReportTemplateDetailed> {
    const response = await axios.post(`/report_templates`, {
      report_template: reportTemplateParams,
    });

    return response.data;
  }

  async editReportTemplate(
    reportTemplate: ReportTemplate
  ): Promise<ReportTemplateDetailed> {
    const { id, ...reportTemplateParams } = reportTemplate;

    const response = await axios.put(`/report_templates/${id}`, {
      report_template: reportTemplateParams,
    });

    return response.data;
  }

  async deleteReportTemplate(reportTemplateId: string): Promise<void> {
    const response = await axios.delete(
      `/report_templates/${reportTemplateId}`
    );

    return response.data;
  }

  async getReportTemplateSheets(
    reportTemplateId: string
  ): Promise<ReportTemplateSheetDetailed[]> {
    const response = await axios.get(
      `/report_templates/${reportTemplateId}/report_template_sheets`
    );

    return response.data;
  }

  async createReportTemplateSheet(
    reportTemplateId: string,
    reportTemplateSheetParams: ReportTemplateSheetParams
  ): Promise<ReportTemplateSheetDetailed> {
    const response = await axios.post(
      `/report_templates/${reportTemplateId}/report_template_sheets`,
      { report_template_sheet: reportTemplateSheetParams }
    );

    return response.data;
  }

  async editReportTemplateSheet(
    reportTemplateId: string,
    reportTemplateSheet: ReportTemplateSheet
  ): Promise<ReportTemplateSheetDetailed> {
    const { id, ...reportTemplateSheetParams } = reportTemplateSheet;

    const response = await axios.put(
      `/report_templates/${reportTemplateId}/report_template_sheets/${id}`,
      {
        report_template_sheet: {
          ...reportTemplateSheetParams,
          // Make sure undefined is null, to properly clear referenceIdentifier
          referenceIdentifier:
            reportTemplateSheetParams.referenceIdentifier == null
              ? null
              : reportTemplateSheetParams.referenceIdentifier,
        },
      }
    );

    return response.data;
  }

  async deleteReportTemplateSheet(
    reportTemplateId: string,
    reportTemplateSheetId: string
  ): Promise<void> {
    const response = await axios.delete(
      `/report_templates/${reportTemplateId}/report_template_sheets/${reportTemplateSheetId}`
    );

    return response.data;
  }

  async createReportTemplateHeader(
    reportTemplateId: string,
    reportTemplateSheetId: string,
    reportTemplateHeaderParams: ReportTemplateHeaderParams
  ): Promise<ReportTemplateHeaderDetailed> {
    const response = await axios.post(
      `/report_templates/${reportTemplateId}/report_template_sheets/${reportTemplateSheetId}/report_template_headers`,
      { report_template_header: reportTemplateHeaderParams }
    );

    return response.data;
  }

  async editReportTemplateHeader(
    reportTemplateId: string,
    reportTemplateSheetId: string,
    reportTemplateHeader: ReportTemplateHeader
  ): Promise<ReportTemplateHeaderDetailed> {
    const { id, ...reportTemplateHeaderParams } = reportTemplateHeader;

    const response = await axios.put(
      `/report_templates/${reportTemplateId}/report_template_sheets/${reportTemplateSheetId}/report_template_headers/${id}`,
      { report_template_header: reportTemplateHeaderParams }
    );

    return response.data;
  }

  async deleteReportTemplateHeader(
    reportTemplateId: string,
    reportTemplateSheetId: string,
    reportTemplateHeaderId: string
  ): Promise<ReportTemplateHeaderDeleteResponse> {
    const response = await axios.delete(
      `/report_templates/${reportTemplateId}/report_template_sheets/${reportTemplateSheetId}/report_template_headers/${reportTemplateHeaderId}`
    );

    return response.data;
  }

  async getReportSheetsCreationInfo(
    reportTemplateId: string,
    documentTemplateId: string
  ): Promise<ReportCreationInfo> {
    const response = await axios.get(
      `/report_templates/${reportTemplateId}/report_sheets_creation_info?document_template_id=${documentTemplateId}`
    );

    return response.data;
  }

  async createReport(
    reportCreationPayload: ReportCreationPayload
  ): Promise<ReportListEntry> {
    const response = await axios.post(`/reports`, reportCreationPayload);

    return response.data;
  }

  async getReport(
    id: string,
    organizationId?: string | null
  ): Promise<ReportDetailed> {
    const response = await axios.get(`/reports/${id}`, {
      params: { organizationId },
    });

    return response.data;
  }

  async deleteReport(id: string): Promise<void> {
    const response = await axios.delete(`/reports/${id}`);

    return response.data;
  }

  async getReportSheet(
    reportId: string,
    sheetId: string,
    organizationId?: string | null
  ): Promise<ReportDetailedSheet> {
    const response = await axios.get(
      `/reports/${reportId}/report_sheets/${sheetId}`,
      { params: { organizationId } }
    );

    return response.data;
  }

  async downloadReport(
    reportId: string
  ): Promise<{ fileName: string; data: string }> {
    const response = await axios.get(`/reports/${reportId}/download_xlsx`, {
      responseType: 'arraybuffer',
    });

    const fileName = extractFileName(response);

    return { fileName: fileName, data: response.data };
  }

  async initReportRefresh(id: string): Promise<ReportDetailed> {
    const response = await axios.post(`/reports/${id}/init_refresh`);

    return response.data;
  }

  async getCpgReport(id: string): Promise<CpgReport> {
    const response = await axios.get(`/cpg_reports/${id}`);

    return response.data;
  }

  async deleteCpgReport(id: string): Promise<void> {
    const response = await axios.delete(`/cpg_reports/${id}`);

    return response.data;
  }
}
