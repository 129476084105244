import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu, { Item as MenuItem } from 'rc-menu';
import { useIamService } from '@/features/iam/services/useIamService';
import { Profile } from '@/features/iam/types';
import { useCommonServices } from '@/services/CommonServicesProvider';
import { displayOrganizationType } from '@/utils/display-utils';
import Button from '../Button';
import { CableContext } from '@/services/CableProvider';

interface Props {
  profile: Profile;
}

export default function ProfileMenu({ profile }: Props) {
  const navigate = useNavigate();
  const iamService = useIamService();
  const { toastService } = useCommonServices();
  const { unsubscribeAll } = useContext(CableContext);

  function switchProfile(organizationId: string) {
    unsubscribeAll();

    toastService.processing(() =>
      iamService
        .switchProfile(organizationId)
        .then(() => window.location.reload())
    );
  }

  function onLogout() {
    unsubscribeAll();

    iamService.signOut().catch((error) => {
      toastService.error(error.response.data.error);
    });

    navigate('/login');
  }

  const usersOrganizations = profile.organizations;

  return (
    <Menu className="!max-h-[30rem]">
      <MenuItem key={'Current users profile menu item'}>
        <div className="flex w-[25rem] items-end">
          <p className="mr-2 h-[3rem] rounded-full border border-grey-40">
            <span className="user-icon" />
          </p>
          <div>
            <p>
              {profile.givenName} {profile.familyName}
            </p>
            <p className="text-description-sm font-normal text-grey-30">
              {profile.preferredUsername}
            </p>
          </div>
        </div>
      </MenuItem>
      {usersOrganizations.map((org) => (
        <MenuItem key={org.id}>
          <div
            className="flex cursor-pointer items-center justify-between"
            onClick={() => switchProfile(org.id)}
          >
            <div className="flex items-center">
              <p className="mr-4 mt-1">
                <span className="profile-icon" />
              </p>
              <p className="font-medium">
                {org.name}
                {` (${displayOrganizationType(org.type)} user)`}
                {org.isDemo && (
                  <span className="badge-primary badge ml-2 text-description-sm">
                    DEMO
                  </span>
                )}
              </p>
            </div>
            {org.id === profile.activeOrganizationId && (
              <div className="flex items-center justify-center rounded-full bg-purple p-1">
                <span className="check-icon mt-1 ml-1 !h-[1rem] !w-[1rem] bg-white" />
              </div>
            )}
          </div>
        </MenuItem>
      ))}
      <MenuItem key={'Navigation logout button'}>
        <Button type="text" textColor="text-black" bold onClick={onLogout}>
          Log Out
        </Button>
      </MenuItem>
    </Menu>
  );
}
