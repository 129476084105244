import TextInput from '@/components/inputs/TextInput';
import { FilterProps } from 'react-table';

type ColumnFilterT<T extends object> = (props: FilterProps<T>) => JSX.Element;

function getDefaultColumnFilter<T extends object>(): ColumnFilterT<T> {
  return function DefaultColumnFilter({
    column: { filterValue, setFilter, Header },
  }) {
    return (
      <TextInput
        value={filterValue || ''}
        onChange={(e) => {
          const value = e.target.value;
          if (value.trim() === '') {
            setFilter(undefined);
          } else {
            setFilter(value);
          }
        }}
        placeholder={`${Header} `}
        title={`${Header}`}
      />
    );
  };
}

export function getDefaultColumn<T extends object>() {
  return {
    Filter: getDefaultColumnFilter<T>(),
    filter: 'text',
  };
}
