import axios from 'axios';
import { FormInputAnswer, FormInputAnswerCloneResponse } from '../types';

export class FormInputAnswerService {
  async editAnswers(
    answerId: number,
    answers: Map<string, any>
  ): Promise<FormInputAnswer> {
    const response = await axios.put(`form_input_answers/${answerId}`, {
      form_input_answer: { answers },
    });

    return response.data;
  }

  async clone(answerId: number): Promise<FormInputAnswerCloneResponse> {
    const response = await axios.post(`form_input_answers/${answerId}/clone`);

    return response.data;
  }

  async remove(answerId: number): Promise<void> {
    const response = await axios.delete(`form_input_answers/${answerId}`);

    return response.data;
  }

  async addReviewCommentComment(
    formInputAnswerId: number,
    content = ''
  ): Promise<FormInputAnswer> {
    const response = await axios.post(
      `form_input_answers/${formInputAnswerId}/comment`,
      { comment: { content } }
    );

    return response.data;
  }
}
