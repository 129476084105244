type IconButtonType = 'circle' | 'square' | 'just-icon';

interface Props {
  icon: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: IconButtonType;
  width?: string;
  heigth?: string;
  small?: boolean;
}

const typeClasses: Map<IconButtonType, string> = new Map([
  [
    'circle',
    'bg-white rounded-full border border-light-grey hover:bg-purple-40',
  ],
  [
    'square',
    'btn btn-square bg-white hover:bg-grey-10 hover:border-grey-20 border-transparent drop-shadow-xl',
  ],
  ['just-icon', ''],
]);

export default function IconButton({
  icon,
  onClick,
  type = 'circle',
  width = 'w-[3rem]',
  heigth,
  small = false,
}: Props) {
  const finalWidth = type === 'square' ? '' : width;
  const finalHeigth = heigth ?? '';
  const paddings = small || type === 'square' ? '' : 'py-2.5 px-4';

  const finalClass = `${finalWidth} ${finalHeigth} ${paddings} ${typeClasses.get(
    type
  )}`;

  return (
    <button className={finalClass} onClick={onClick} type="button">
      <span className={icon} />
    </button>
  );
}
