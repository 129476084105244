import { ReactNode, createContext, useContext } from 'react';
import { HelpCenterService } from '.';

const ServiceContext = createContext<HelpCenterService>(
  new HelpCenterService()
);

export function HelpCenterServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const helpCenterService = new HelpCenterService();

  return (
    <ServiceContext.Provider value={helpCenterService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useHelpCenterService(): HelpCenterService {
  return useContext(ServiceContext);
}
