import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/css/tailwind.css';
import './assets/css/main.css';
import 'rc-dropdown/assets/index.css';
import NetworkService from './services/NetworkService';
import SentryService from './services/SentryService';

SentryService.init();
NetworkService.init();

createRoot(document.getElementById('root') as HTMLElement).render(<App />);
