import * as Sentry from '@sentry/react';
import { Component, useEffect } from 'react';

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export default class SentryService extends Component {
  static init() {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_URL,
      environment: import.meta.env.VITE_SENTRY_ENV,
      // Disable since GlitchTip doesn't support it
      // https://gitlab.com/glitchtip/glitchtip-backend/-/issues/206
      autoSessionTracking: false,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
        Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', import.meta.env.VITE_BASE_URL],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}
