import {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
  useEffect,
} from 'react';

const INITIAL_STATE = false;
const STATE_STORAGE_KEY = 'sidebarCollapsedStateStorageKey';

const SidebarCollapsedStateContext = createContext<boolean>(INITIAL_STATE);
const SidebarCollapsedStateDispatchContext =
  createContext<Dispatch<ActionType> | null>(null);

type ActionType = 'toggle';

const initializer = () => {
  const storageValue = localStorage.getItem(STATE_STORAGE_KEY);

  if (storageValue === null) {
    return INITIAL_STATE;
  }

  return (JSON.parse(storageValue) as boolean) || INITIAL_STATE;
};

function reducer(state: boolean, action: ActionType) {
  switch (action) {
    case 'toggle':
      return !state;
    default:
      throw new Error();
  }
}

export default function SidebarCollapsedStateProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const [sidebarCollapsed, dispatch] = useReducer(reducer, initializer());

  useEffect(() => {
    localStorage.setItem(STATE_STORAGE_KEY, JSON.stringify(sidebarCollapsed));
  }, [sidebarCollapsed]);

  return (
    <SidebarCollapsedStateContext.Provider value={sidebarCollapsed}>
      <SidebarCollapsedStateDispatchContext.Provider value={dispatch}>
        {children}
      </SidebarCollapsedStateDispatchContext.Provider>
    </SidebarCollapsedStateContext.Provider>
  );
}

export function useSidebarCollapsedState() {
  return useContext(SidebarCollapsedStateContext);
}

export function useSidebarCollapsedStateDispatch() {
  return useContext(SidebarCollapsedStateDispatchContext);
}
