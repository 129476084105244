import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import SidebarAccordion from './SidebarAccordion';
import { Link, useLocation } from 'react-router-dom';
import { useCommonServices } from '@/services/CommonServicesProvider';
import { AwardProgram } from '@/features/awards/types';
import { PlanType, sortedPlanTypes } from '@/features/iam/types';
import { useSidebarCollapsedState } from '../reducer/SidebarReducer';
import { UserCircleIcon } from '@heroicons/react/24/outline';

interface Props {
  grants: AwardProgram[];
}

export default function SidebarContent({ grants }: Props) {
  const { storageService } = useCommonServices();
  const location = useLocation();

  const collapsed = useSidebarCollapsedState();

  const roles = storageService.getUserRoles();
  const accessiblePlans = sortedPlanTypes([
    ...new Set((roles ?? []).flatMap((r) => r.assignedPlans)),
  ]);

  function getUrlOption(planType: PlanType) {
    switch (planType) {
      case 'CPG':
        return 'cpg';
      case 'WORK_PLAN':
        return 'wp';
      case 'SPEND_PLAN':
        return 'sp';
    }
  }

  const templatesURLOptions: string[] = [];
  const documentsURLOptions: string[] = [];

  for (const plan of accessiblePlans) {
    const urlOption = getUrlOption(plan);

    templatesURLOptions.push(urlOption);
    documentsURLOptions.push(`${urlOption}s`);
  }

  return (
    <Sidebar collapsed={collapsed} collapsedWidth="0px">
      <div className="flex h-[calc(100vh-60px)] border-r-0.5 border-r-grey-20 bg-white">
        <Menu className="w-full">
          <div className="pt-7 pb-14 text-sidebar">
            <MenuItem
              key={`Sidebar content Home`}
              className="mb-3 max-h-8"
              component={<Link className="menu-item" to="/" />}
              active={location.pathname === '/'}
            >
              <div className="ml-2">
                <div className="flex">
                  <span className="grant-summary-icon mt-2.5 ml-1" />
                  <p className="my-3 ml-4 text-sidebar">Home</p>
                </div>
              </div>
            </MenuItem>

            <MenuItem
              key={`Sidebar content File storage`}
              className="mb-3 max-h-8"
              component={<Link className="menu-item" to="/file-storage" />}
              active={location.pathname.startsWith('/file-storage')}
            >
              <div className="ml-2">
                <div className="flex">
                  <span className="file-storage-icon mt-2.5 ml-1" />
                  <p className="my-3 ml-4 text-sidebar">File library</p>
                </div>
              </div>
            </MenuItem>

            {storageService.isStateOrGodUser() && (
              <MenuItem
                key={`Sidebar content Reports`}
                className={`${storageService.isGodUser() ? 'mb-3' : 'mb-5'} max-h-8`}
                component={<Link to="/reports" />}
                active={location.pathname.startsWith('/reports')}
              >
                <div className="ml-2 flex items-center">
                  <span className="reports-icon ml-1" />
                  <p className="my-3 ml-5">Reports</p>
                </div>
              </MenuItem>
            )}

            {storageService.isGodUser() && (
              <MenuItem
                key={`Sidebar content Grants`}
                className="mb-5 max-h-8"
                component={<Link to="/grants" />}
                active={location.pathname.startsWith('/grants')}
              >
                <div className="ml-2 flex items-center">
                  <span className="instructions-icon ml-1" />
                  <p className="my-3 ml-5">Grants</p>
                </div>
              </MenuItem>
            )}

            {storageService.isStateOrGodUser() &&
              templatesURLOptions.length > 0 && (
                <div className="pt-5">
                  <p className="ml-7 text-sidebar-sm text-grey-30">TEMPLATES</p>
                  <SidebarAccordion
                    menuType={'templates'}
                    menuNames={templatesURLOptions}
                    grants={grants}
                  />
                </div>
              )}

            {documentsURLOptions.length > 0 && (
              <div className="pt-5">
                <p className="ml-7 text-sidebar-sm text-grey-30">DOCUMENTS</p>
                <SidebarAccordion
                  menuType={'documents'}
                  menuNames={documentsURLOptions}
                  grants={grants}
                />
              </div>
            )}

            <div className="divider mx-2 my-0" />
            <p className="ml-7 pt-5 text-sidebar-sm text-grey-30">SETTINGS</p>
            <MenuItem
              key={`Sidebar content Account settings`}
              className="mt-5 mb-5 max-h-8"
              component={<Link to="/account-settings" />}
              active={location.pathname.startsWith('/account-settings')}
            >
              <div className="ml-2 flex">
                <UserCircleIcon className="mt-2 h-[1.5rem] w-[1.5rem] text-grey-20" />
                <p className="my-3 ml-4">Account</p>
              </div>
            </MenuItem>
            <MenuItem
              key={`Sidebar content Organizations`}
              className="max-h-8"
              component={<Link to="/organizations" />}
              active={location.pathname.startsWith('/organizations')}
            >
              <div className="ml-2 flex">
                <span className="user-organizations-icon mt-3 ml-1" />
                <p className="my-3 ml-5">Organization</p>
              </div>
            </MenuItem>
          </div>
        </Menu>
      </div>
    </Sidebar>
  );
}
