import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Overview = lazy(() => import('../pages/overview/AwardsOverviewPage'));

const AwardOverviewRoutes = () => (
  <Routes>
    <Route path="/:awardId" element={<Overview />} />
  </Routes>
);

export default AwardOverviewRoutes;
