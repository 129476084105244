export function openLink(link: string | null) {
  if (!link) {
    return;
  }
  window.open(link, '_blank')?.focus();
}

export function getAdminPanelBaseUrl() {
  const baseUrl = import.meta.env.VITE_BASE_URL;
  return new URL('/admin', baseUrl).toString();
}

export function getAdminPanelOrganizationEditUrl({
  isGodUser,
  organizationId,
}: {
  isGodUser: boolean;
  organizationId: string;
}) {
  if (!isGodUser) {
    return null;
  }

  const baseUrl = getAdminPanelBaseUrl();

  return `${baseUrl}/organizations/${organizationId}/edit`;
}
