import { ReactNode, createContext, useContext } from 'react';
import { ReportService } from '.';

const ServiceContext = createContext<ReportService>(new ReportService());

export function ReportServiceProvider({ children }: { children: ReactNode }) {
  const reportService = new ReportService();

  return (
    <ServiceContext.Provider value={reportService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useReportService(): ReportService {
  return useContext(ServiceContext);
}
