import axios from 'axios';
import {
  BudgetItemsResponse,
  BudgetItemFormData,
  BudgetItem,
} from '@/features/budget-categories/types';
import { Summary } from '@/features/documents/types';

export abstract class BudgetItemService {
  basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  async getBudgetItems(documentId: string): Promise<BudgetItemsResponse> {
    const response = await axios.get(
      `${this.basePath}/${documentId}/budget_categories`
    );

    return response.data;
  }

  async editBudgetItem(
    documentId: string,
    budgetItemId: string,
    editedBudgetItem: BudgetItemFormData
  ): Promise<BudgetItem> {
    const { connectedContainerPairs, connectedActivityPairs, ...editPayload } =
      editedBudgetItem;

    // eslint-disable-next-line @typescript-eslint/dot-notation
    delete editPayload['id'];

    const response = await axios.put(
      `${this.basePath}/${documentId}/budget_items/${budgetItemId}`,
      {
        budget_item: {
          ...editPayload,
          connectedContainerIds: connectedContainerPairs?.map(
            (containerPair) => containerPair.child.id
          ),
          connectedActivityIds: connectedActivityPairs?.map(
            (containerPair) => containerPair.child.id
          ),
        },
      }
    );

    return response.data;
  }

  async addBudgetItem(
    documentId: string,
    budgetCategoryId: string,
    newBudgetItem: BudgetItemFormData
  ): Promise<BudgetItem> {
    const { connectedContainerPairs, connectedActivityPairs, ...addPayload } =
      newBudgetItem;

    const response = await axios.post(
      `${this.basePath}/${documentId}/budget_items`,
      {
        budgetCategoryId,
        budget_item: {
          ...addPayload,
          connectedContainerIds: connectedContainerPairs?.map(
            (containerPair) => containerPair.child.id
          ),
          connectedActivityIds: connectedActivityPairs?.map(
            (containerPair) => containerPair.child.id
          ),
        },
      }
    );

    return response.data;
  }

  async importBudgetItems(
    documentId: string,
    budgetCategoryId: string,
    file: File
  ): Promise<BudgetItem[]> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post(
      `${this.basePath}/${documentId}/budget_items/import?budget_category_id=${budgetCategoryId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }

  async removeBudgetItem(
    documentId: string,
    budgetItemId: string
  ): Promise<void> {
    const response = await axios.delete(
      `${this.basePath}/${documentId}/budget_items/${budgetItemId}`
    );

    return response.data;
  }

  async removeBudgetItems(
    documentId: string,
    budgetItemIds: string[]
  ): Promise<void> {
    const response = await axios.delete(
      `${this.basePath}/${documentId}/budget_items/destroy_many?ids=${budgetItemIds.join(',')}`
    );

    return response.data;
  }

  async getBudgetSummary(documentId: string): Promise<Summary> {
    const response = await axios.get(
      `${this.basePath}/${documentId}/budget_summary`
    );

    return response.data;
  }

  async addBudgetItemComment(
    documentId: string,
    budgetItemId: string,
    content = ''
  ): Promise<BudgetItem> {
    const response = await axios.post(
      `${this.basePath}/${documentId}/budget_items/${budgetItemId}/comment`,
      { comment: { content } }
    );

    return response.data;
  }
}
