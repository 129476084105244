import { Outlet } from 'react-router-dom';

import UnauthenticatedView from '@/layouts/UnauthenticatedView';

const UnauthenticatedLayoutRouter = () => (
  <UnauthenticatedView>
    <Outlet />
  </UnauthenticatedView>
);

export default UnauthenticatedLayoutRouter;
