import { useCommonServices } from '@/services/CommonServicesProvider';
import { useIamService } from '@/features/iam/services/useIamService';
import googlePlayBadge from '@/assets/css/images/icons/google-play-badge.png';
import appStoreBadge from '@/assets/css/images/icons/apple-app-store-badge.svg';
import UnathenticatedBodyView from '@/layouts/UnauthenticatedBodyView';
import Button from '@/components/Button';

interface Props {
  setCurrentStep: (step: number) => void;
  setQrCode: (qrCode: string) => void;
  setBackupCodes: (backupCodes: string[]) => void;
}

export default function GetAuthenticator({
  setCurrentStep,
  setQrCode,
  setBackupCodes,
}: Props) {
  const iam = useIamService();
  const { toastService } = useCommonServices();

  function setUpTwoFactor() {
    toastService.processing(() =>
      iam.setup2fa().then((response) => {
        setQrCode(response.qrCode);
        setBackupCodes(response.backupCodes);
        setCurrentStep(1);
      })
    );
  }

  return (
    <UnathenticatedBodyView
      title="Download Authenticator App"
      subTitle=" Your organization requires you to use two-factor authentication to login."
    >
      <div className="max-w-[31.625rem] text-center">
        <p className="mb-3">
          Download and install an authentication app on your phone.
        </p>
        <div className="flex flex-row items-center justify-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={googlePlayBadge}
              style={{ height: '60px' }}
              alt="Google Play Store"
            />
          </a>
          <div style={{ height: '60px' }}>
            <a
              href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={appStoreBadge}
                alt="Apple App Store"
                style={{ margin: '8%' }}
              />
            </a>
          </div>
        </div>
        <p className="my-4 pb-3">
          Alternatively, if you do not have a smartphone, you can use a
          browser-based authenticator like {''}
          <a
            href="https://authenticator.cc/"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold underline"
          >
            Authenticator
          </a>
          .
        </p>
        <div className="my-4">
          <Button type="secondary" onClick={setUpTwoFactor}>
            Continue
          </Button>
        </div>
      </div>
    </UnathenticatedBodyView>
  );
}
