import { Route, Routes } from 'react-router-dom';
import AwardSummaryRoutes from '../features/awards/routes/AwardSummaryRoutes';
import WorkPlanTemplatesRoutes from '../features/work-plan-templates/routes/WorkPlanTemplatesRoutes';
import ReportsRoutes from '../features/reports/routes/ReportsRoutes';
import OrganizationSettingsRoutes from '../features/iam/routes/OrganizationSettings';
import OrganizationsRoutes from '../features/iam/routes/OrganizationsRoutes';
import Login from '@/features/iam/pages/login/Login';
import AuthenticatedLayoutRouter from './AuthenticatedLayoutRouter';
import NavigationLayoutRouter from './NavigationLayoutRouter';
import UnauthenticatedLayoutRouter from './UnauthenticatedViewLayoutRoute';
import SettingsLayoutRoute from '@/features/iam/routes/SettingsLayoutRoute';
import OrganizationSettingsLayoutRoute from '../features/iam/routes/OrganizationSettingsLayoutRoute';
import OrganizationUsersTable from '@/features/iam/pages/organization-users-table/OrganizationUsersTable';
import OrganizationUserRoutes from '../features/iam/routes/OrganizationUser';
import AcceptInvitationPage from '@/features/iam/pages/user-invitation/AcceptInvitationPage';
import { TrailingSlashAdder } from './TrailingSlashAdder';
import CpgTemplatesRoutes from '@/features/cpg-templates/routes/CpgTemplatesRoutes';
import CpgsRoutes from '@/features/cpg-documents/routes/CpgsRoutes';
import WorkPlanDocumentsRoutes from '@/features/work-plan-documents/routes/WorkPlanDocumentsRoutes';
import SpendPlanTemplatesRoutes from '@/features/spend-plan-templates/routes/SpendPlanTemplatesRoutes';
import SpendPlanDocumentsRoutes from '@/features/spend-plan-documents/routes/SpendPlanDocumentsRoutes';
import TwoFactor from '@/features/iam/pages/two_factor/TwoFactor';
import OrganizationRolesRoutes from '@/features/iam/routes/OrganizationRolesRoutes';
import NotificationsRoutes from '@/features/notifications/routes/NotificationsRoutes';
import ForgotPasswordPage from '@/features/iam/pages/password-reset/ForgotPasswordPage';
import ResetPasswordPage from '@/features/iam/pages/password-reset/RessetPasswordPage';
import FileStorageRoutes from '@/features/file-storage/routes/FileStorageRoutes';
import FileDownloadRoutes from '@/features/file-download/routes/FileDownloadRoutes';
import HelpCenterRoutes from '@/features/help-center/routes/HelpCenterRoutes';
import PersonnelReportRoutes from '@/features/personnel-report/routes/PersonnelReportRoutes';
import AccountSettingsRoutes from '@/features/account-settings/routes/AccountSettingsRoutes';
import AwardDefinitionRoutes from '@/features/award-definitions/routes/AwardDefinitionRoutes';
import SignInAsRedirect from '@/features/iam/pages/login/SignInAsRedirect';

const AppRouter = () => (
  <>
    <TrailingSlashAdder />
    <Routes>
      <Route element={<AuthenticatedLayoutRouter />}>
        <Route path="/reports/*">
          <Route
            path="personnel-reports/*"
            element={<PersonnelReportRoutes />}
          />
          <Route path="*" element={<ReportsRoutes />} />
        </Route>
        <Route element={<NavigationLayoutRouter />}>
          <Route path="/notifications/*" element={<NotificationsRoutes />} />
          <Route path="/file-storage/*" element={<FileStorageRoutes />} />
          <Route path="/file-download/*" element={<FileDownloadRoutes />} />
          <Route path="/help-center/*" element={<HelpCenterRoutes />} />
          <Route path="/grants/*" element={<AwardDefinitionRoutes />} />
          <Route path="/*" element={<AwardSummaryRoutes />} />
          <Route path="/award-programs/:awardProgramId/">
            <Route path="cpg-templates/*" element={<CpgTemplatesRoutes />} />
            <Route path="cpgs/*" element={<CpgsRoutes />} />
            <Route
              path="wp-templates/*"
              element={<WorkPlanTemplatesRoutes />}
            />
            <Route path="wps/*" element={<WorkPlanDocumentsRoutes />} />
            <Route
              path="sp-templates/*"
              element={<SpendPlanTemplatesRoutes />}
            />
            <Route path="sps/*" element={<SpendPlanDocumentsRoutes />} />
          </Route>
          <Route element={<SettingsLayoutRoute />}>
            <Route path="/organizations/" element={<OrganizationsRoutes />} />
            <Route element={<OrganizationSettingsLayoutRoute />}>
              <Route
                path="/organizations/:organizationId/"
                element={<OrganizationSettingsRoutes />}
              />
              <Route
                path="/organizations/:organizationId/roles/*"
                element={<OrganizationRolesRoutes />}
              />
              <Route
                path="/organizations/:organizationId/users/"
                element={<OrganizationUsersTable />}
              />
              <Route
                path="/organizations/:organizationId/users/:organizationUserId/"
                element={<OrganizationUserRoutes />}
              />
            </Route>
          </Route>
          <Route
            path="/account-settings/*"
            element={<AccountSettingsRoutes />}
          />
        </Route>
      </Route>
      <Route element={<UnauthenticatedLayoutRouter />}>
        <Route path="/sign-in-as/" element={<SignInAsRedirect />} />
        <Route path="/login/" element={<Login />} />
        <Route path="/two_factor" element={<TwoFactor />} />
        <Route path="/forgot-password/" element={<ForgotPasswordPage />} />
        <Route path="/users/password/edit/" element={<ResetPasswordPage />} />
        <Route
          path="/users/invitation/accept/"
          element={<AcceptInvitationPage />}
        />
      </Route>
    </Routes>
  </>
);

export default AppRouter;
