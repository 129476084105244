/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Role, UserType } from '@/features/iam/types';

// @ts-ignore: Adding custom window field
if (!window._storageServiceListeners) {
  // @ts-ignore: Adding custom window field
  window._storageServiceListeners = [];
}

class StorageService {
  static USER_TYPE = 'USER_TYPE';

  static USER_EMAIL = 'USER_EMAIL';

  static USER_ROLES = 'USER_ROLES';

  static AUTH_TOKEN = 'AUTH_TOKEN';

  clear(): void {
    localStorage.clear();
  }

  setToken(token: string): void {
    localStorage.setItem(StorageService.AUTH_TOKEN, token);
    this.notifyTokenChange(token);
  }

  getToken(): string | null {
    return localStorage.getItem(StorageService.AUTH_TOKEN);
  }

  addTokenListener(listener: (token: string) => void) {
    // @ts-ignore: Adding custom window field
    window._storageServiceListeners.push(listener);
  }

  removeTokenListener(listener: (token: string) => void) {
    // @ts-ignore: Adding custom window field
    window._storageServiceListeners = window._storageServiceListeners.filter(
      (l) => l !== listener
    );
  }

  private notifyTokenChange(token: string) {
    // @ts-ignore: Adding custom window field
    window._storageServiceListeners.forEach((listener) => listener(token));
  }

  getBearerToken(): string | null {
    const token = this.getToken();

    if (!token) {
      return null;
    }

    return `Bearer ${token}`;
  }

  setUserType(type: UserType): void {
    localStorage.setItem(StorageService.USER_TYPE, type);
  }

  getUserType(): UserType {
    return localStorage.getItem(StorageService.USER_TYPE) as UserType;
  }

  setUserRoles(roles?: Role[]): void {
    if (roles) {
      localStorage.setItem(StorageService.USER_ROLES, JSON.stringify(roles));
    } else {
      localStorage.removeItem(StorageService.USER_ROLES);
    }
  }

  getUserRoles(): Role[] | null {
    const storageValue = localStorage.getItem(StorageService.USER_ROLES);

    if (storageValue) {
      return JSON.parse(storageValue);
    }

    return null;
  }

  isStateUser(): boolean {
    const type = this.getUserType();

    return type === 'STATE';
  }

  isStateOrGodUser(): boolean {
    const type = this.getUserType();

    return type === 'STATE' || type === 'GOD';
  }

  isGodUser(): boolean {
    const type = this.getUserType();

    return type === 'GOD';
  }

  isLocalOrGodUser(): boolean {
    const type = this.getUserType();

    return type === 'LOCAL' || type === 'GOD';
  }

  setUserEmail(type: string): void {
    localStorage.setItem(StorageService.USER_EMAIL, type);
  }

  getUserEmail(): string {
    return localStorage.getItem(StorageService.USER_EMAIL) as string;
  }
}

export default StorageService;
