import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const HelpCenterPage = lazy(
  () => import('../pages/help-center/HelpCenterPage')
);

export default function HelpCenterRoutes() {
  return (
    <Routes>
      <Route path="/:documentId" element={<HelpCenterPage />} />
    </Routes>
  );
}
