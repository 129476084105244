import { ReactNode, createContext, useContext } from 'react';
import { CpgTemplateService } from './CpgTemplateService';
import { CpgTemplateFormInputService } from './CpgTemplateFormInputService';

interface CpgTemplateServices {
  cpgTemplateService: CpgTemplateService;
  cpgTemplateFormInputService: CpgTemplateFormInputService;
}

const ServiceContext = createContext<CpgTemplateServices>({
  cpgTemplateService: new CpgTemplateService(),
  cpgTemplateFormInputService: new CpgTemplateFormInputService(),
});

export function CpgTemplateServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const cpgTemplateService = new CpgTemplateService();
  const cpgTemplateFormInputService = new CpgTemplateFormInputService();

  return (
    <ServiceContext.Provider
      value={{ cpgTemplateService, cpgTemplateFormInputService }}
    >
      {children}
    </ServiceContext.Provider>
  );
}

export function useCpgTemplateServices(): CpgTemplateServices {
  return useContext(ServiceContext);
}
