import TextInput from '@/components/inputs/TextInput';
import { useCommonServices } from '@/services/CommonServicesProvider';
import { FormEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIamService } from '@/features/iam/services/useIamService';
import UnathenticatedBodyView from '@/layouts/UnauthenticatedBodyView';

export default function ResetPasswordPage() {
  const iam = useIamService();
  const { toastService } = useCommonServices();

  const resetPasswordToken = useSearchParams()[0].get(
    'reset_password_token'
  ) as string;

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordsValidationFailed, setPasswordsValidationFailed] =
    useState(false);

  function resetState() {
    setPassword('');
    setPasswordConfirmation('');
    setPasswordsValidationFailed(false);
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
      setPasswordsValidationFailed(true);
      return;
    }

    toastService.processing(
      () =>
        iam.resetPassword(password, resetPasswordToken).then((result) => {
          resetState();
          window.location.href = '/login';

          return result;
        }),
      (error) => {
        return error.response.status === 422;
      }
    );
  };

  return (
    <UnathenticatedBodyView
      title="Reset password"
      subTitle="Enter your new password"
      formConfig={{
        onSubmit,
        mainButtonTitle: 'Reset password',
        autoComplete: 'off',
      }}
    >
      <TextInput
        headerName="Create password"
        headerClassName="text-white mt-2"
        value={password}
        type="password"
        placeholder="Password"
        required
        onChange={(password) => {
          setPassword(password.target.value);
          setPasswordsValidationFailed(false);
        }}
        autoComplete="new-password"
      />

      <TextInput
        headerName="Confirm password"
        headerClassName="text-white mt-5"
        value={passwordConfirmation}
        type="password"
        placeholder="Password confirmation"
        required
        onChange={(passwordConfirmation) => {
          setPasswordConfirmation(passwordConfirmation.target.value);
          setPasswordsValidationFailed(false);
        }}
        autoComplete="off"
        isValid={!passwordsValidationFailed}
        name={passwordsValidationFailed ? 'Passwords must match' : undefined}
      />
    </UnathenticatedBodyView>
  );
}
