import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { getDefaultColumn } from './filters/DefaultColumnFilter';
import { sortTypes } from '@/components/table/Sortable';
import getTableBody, {
  SelectionProps,
  TableColumn,
  TableOnClickType,
} from './TableBody';
import { useEffect } from 'react';

interface Props<T extends object> {
  data: T[];
  columns: TableColumn<T>[];
  filterHidden?: (column: any) => boolean;
  onClick?: TableOnClickType<T>;
  highlight?: (value: T) => boolean;
  selection?: SelectionProps<T>;
}

const useCommonTable = <T extends object>(props: Props<T>) => {
  const { columns, data } = props;

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageIndex },
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { filters, sortBy },
  } = useTable(
    {
      columns,
      data,
      sortTypes,
      defaultColumn: getDefaultColumn<T>(),
      autoResetPage: false,
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    gotoPage(0);
  }, [filters, sortBy, gotoPage]);

  return getTableBody<T>({
    ...props,
    page,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    paginationProps: {
      gotoPage,
      canPreviousPage,
      canNextPage,
      pageCount,
      nextPage,
      previousPage,
      pageIndex,
    },
  });
};

export default useCommonTable;
