import { ReactNode, Suspense } from 'react';

import Nav from '@/components/navigation/Nav';
import SidebarMenu from '@/components/navigation/sidebar/SidebarMenu';
import Loader from '@/components/Loader';
import Init from '@/Init';
import SidebarCollapsedStateProvider from '@/components/navigation/reducer/SidebarReducer';

interface Props {
  children: ReactNode;
  withoutNavigation?: boolean;
}

export default function NavigatorView({
  children,
  withoutNavigation = false,
}: Props) {
  return (
    <SidebarCollapsedStateProvider>
      {!withoutNavigation && <Nav />}
      <Init>
        <div className="flex bg-white font-base">
          {!withoutNavigation && <SidebarMenu />}
          <div
            className={`app bg-grey-10 ${withoutNavigation ? '!max-h-none' : ''}`}
          >
            <Suspense fallback={<Loader />}>{children}</Suspense>
          </div>
        </div>
      </Init>
    </SidebarCollapsedStateProvider>
  );
}
