import Logo from '@/components/Logo';
import { Fragment } from 'react';
import { Toaster } from 'react-hot-toast';

const UnauthenticatedView = ({ children }) => (
  <Fragment>
    <Toaster containerClassName={'mt-16'} />
    <div>
      <div
        className={`flex h-screen w-screen flex-col items-center overflow-auto bg-purple p-16 text-white`}
      >
        <Logo />

        <div className="flex grow flex-col items-center">{children}</div>
      </div>
    </div>
  </Fragment>
);

export default UnauthenticatedView;
