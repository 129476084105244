import axios, { AxiosResponse } from 'axios';
import {
  AwardOverview,
  AwardProgram,
  AwardsSummaryResponse,
  AwardProgramsResponse,
  FundingType,
  AwardsSummaryPermissions,
} from '@/features/awards/types';
import { Container } from '@/features/templates/types';
import { AddAwardFormValues } from '../pages/summary/components/add-award/AddAwardModal';
import { EditAwardFormValues } from '../pages/overview/components/EditAwardModal';
import { PaginatedDataRequest } from '@/components/table/BackendTable';
import { getQueryParamsFromPaginationRequest } from '@/utils/pagination-utils';

export class AwardService {
  async getAwardsSummary(
    request: PaginatedDataRequest
  ): Promise<AwardsSummaryResponse> {
    const queryParams = getQueryParamsFromPaginationRequest(request);
    const response = await axios.get(`/awards/summary?${queryParams}`);

    return response.data;
  }

  async getAwardsSummaryPermissions(): Promise<{
    permissions: AwardsSummaryPermissions;
  }> {
    const response = await axios.get('/awards/summary_permissions');

    return response.data;
  }

  async getTemplateAwardOverview(awardId: string): Promise<AwardOverview> {
    const response = await axios.get(`/awards/${awardId}/template_summary`);

    return response.data;
  }

  async addNewFunding(
    awardId: string,
    fundingType: FundingType,
    allowedLhjIds: string[],
    fundingName?: string,
    addNewWorkPlanTemplate?: boolean
  ): Promise<AwardOverview> {
    const response = await axios.post(`/awards/${awardId}/funding`, {
      type: fundingType,
      lhjIds: allowedLhjIds,
      fundingName,
      addNewWorkPlanTemplate,
    });

    return response.data;
  }

  async getDocumentAwardOverview(
    awardId: string,
    organizationId: string
  ): Promise<AwardOverview> {
    const response = await axios.get(
      `/awards/${awardId}/document_summary/?organization_id=${organizationId}`
    );

    return response.data;
  }

  async optOutOfFunding(
    awardId: string,
    fundingId: number,
    organizationId: string
  ): Promise<AwardOverview> {
    const response = await axios.post(`/awards/${awardId}/opt_out_of_funding`, {
      fundingId,
      organizationId,
    });

    return response.data;
  }

  async archiveDocumentFundings(
    awardId: string,
    organizationId: string
  ): Promise<AwardOverview> {
    const response = await axios.post(
      `/awards/${awardId}/archive_document_fundings`,
      {
        organizationId,
      }
    );

    return response.data;
  }

  async getContainers(awardId: number): Promise<Container[]> {
    const response: AxiosResponse<Container[]> = await axios.get(
      `/awards/${awardId}/containers`
    );

    return response.data;
  }

  async getAwardPrograms(): Promise<AwardProgramsResponse> {
    const response: AxiosResponse<AwardProgramsResponse> =
      await axios.get('/grants');

    return response.data;
  }

  async getAwardProgram(id: string): Promise<AwardProgram> {
    const response: AxiosResponse<AwardProgram> = await axios.get(
      `/grants/${id}`
    );

    return response.data;
  }

  async addNewAward(awardParams: AddAwardFormValues): Promise<void> {
    const response = await axios.post('/awards', {
      award: awardParams,
    });

    return response.data;
  }

  async editAward(
    awardId: string,
    awardParams: EditAwardFormValues
  ): Promise<AwardOverview> {
    const response = await axios.put(`/awards/${awardId}`, {
      award: awardParams,
    });

    return response.data;
  }
}
