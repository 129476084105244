import axios, { AxiosResponse } from 'axios';
import { OrganizationUser } from '@/features/iam/types';
import { NotificationTypes } from '@/features/notifications/types';
import {
  User,
  Account,
  ResubscribeToEmailsResponse,
  EmailSuppressionStatusResponse,
} from '../types';

export class AccountSettingsService {
  async getAccount(): Promise<Account> {
    const response: AxiosResponse<Account> = await axios.get('/users/account');

    return response.data;
  }

  async updateUser(
    userId: number,
    firstName: string,
    lastName: string
  ): Promise<User> {
    const response = await axios.put(`/users/${userId}`, {
      user: {
        firstName,
        lastName,
      },
    });

    return response.data;
  }

  async updateNotificationSettings(
    organizationId: number,
    organizationUserId: number,
    suppressedNotifications: NotificationTypes
  ): Promise<OrganizationUser> {
    const response = await axios.put(
      `/iam/organizations/${organizationId}/organization_users/${organizationUserId}`,
      {
        organization_user: {
          suppressedNotifications,
        },
      }
    );

    return response.data;
  }

  async emailSuppressionStatus(): Promise<EmailSuppressionStatusResponse> {
    const response = await axios.get(`/users/email_suppression_status`);

    return response.data;
  }

  async resubscribeToEmails(): Promise<ResubscribeToEmailsResponse> {
    const response = await axios.post(`/users/resubscribe_to_emails`);

    return response.data;
  }
}
