import { ReactNode, createContext, useContext } from 'react';
import { FileDownloadService } from '.';

const ServiceContext = createContext<FileDownloadService>(
  new FileDownloadService()
);

export function FileDownloadServiceProvider({
  children,
}: {
  children: ReactNode;
}) {
  const fileDownloadService = new FileDownloadService();

  return (
    <ServiceContext.Provider value={fileDownloadService}>
      {children}
    </ServiceContext.Provider>
  );
}

export function useFileDownloadService(): FileDownloadService {
  return useContext(ServiceContext);
}
