import AppRouter from './routes';
import { BrowserRouter } from 'react-router-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Colors,
} from 'chart.js';

import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CommonServicesProvider } from './services/CommonServicesProvider';
import FeatureServicesProvider from './services/FeatureServicesProvider';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  BarElement,
  Colors
);
const queryClient = new QueryClient();

const App = () => (
  <ErrorBoundary>
    <CommonServicesProvider>
      <FeatureServicesProvider>
        <ProSidebarProvider>
          <FluentProvider theme={webLightTheme}>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <AppRouter />
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              </QueryClientProvider>
            </BrowserRouter>
          </FluentProvider>
        </ProSidebarProvider>
      </FeatureServicesProvider>
    </CommonServicesProvider>
  </ErrorBoundary>
);

export default App;
