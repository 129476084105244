import axios from 'axios';
import { HelpDocument } from '../types';

export class HelpCenterService {
  async fetchHelpCenterContent(id: string): Promise<HelpDocument> {
    const response = await axios.get(`/help_center/${id}`);

    return response.data;
  }
}
