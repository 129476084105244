import AwardOverviewRoutes from '@/features/awards/routes/AwardOverviewRoutes';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const Cpgs = lazy(() => import('../pages/cpgs'));

const Cpg = lazy(() => import('../pages/cpg-flow'));

const CpgsRoutes = () => (
  <Routes>
    <Route path="" element={<Cpgs />} />
    <Route path="/award-overview/*" element={<AwardOverviewRoutes />} />
    <Route path="/:cpgId" element={<Cpg />} />
  </Routes>
);

export default CpgsRoutes;
