import axios, { AxiosResponse } from 'axios';

export class FileDownloadService {
  async downloadFile(
    entryType: string,
    entryId: string,
    fileId: string | null
  ): Promise<AxiosResponse<Blob>> {
    const response = await axios.get(
      `/file_downloads/${entryId}?entry_type=${entryType}&file_id=${fileId}`,
      {
        responseType: 'blob',
      }
    );

    return response;
  }
}
