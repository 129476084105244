import axios from 'axios';
import { FormInputsResponse } from '../types';

export abstract class FormInputService {
  basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  async getFormInputs(
    templateOrDocumentId: string
  ): Promise<FormInputsResponse> {
    const response = await axios.get(
      `${this.basePath}/${templateOrDocumentId}/form_inputs`
    );

    return response.data;
  }
}
